import { TextField } from '@mui/material';
import React, { useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import { useApp } from '../context/AppProvider';
import { notify } from '../helpers/functions';

const TaskComment = ({id}) => {

    const [comment, setComment] = useState('')
    const {setFetch, fetch} = useApp()
    const {user} = useLogin()
    const [btnLoading, setBtnLoading] = useState(null);

    const handleCreate = () => {

        if (!comment) {
            notify({message: 'Comment is required', error:true})
            return
        }

        const commentData = {
            comment,
            commentID: id,
            commentBy: user.name
        }

        setBtnLoading(true);
        debtsAPI.post(`/tasks/createComment`, commentData)
        .then(res => {
            setBtnLoading(false);
            if (res.data.success) {
                notify({message:res.data.message, success:true});
                setFetch(!fetch);
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false);
            console.log(err)
        })
    }

    return (
        <form>
            <div className='row'>
                <div className="col-md-12 mb-3 pt-2">
                    <TextField
                        label='Your Comment'
                        className='form-control'
                        onChange={e => setComment(e.target.value)}
                        multiline={true}
                        maxRows={5} 
                    />
                </div>
                <div className="col-md-12">
                    {
                            btnLoading
                        ?
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="visually-hidden">Loading...</span>
                            </button>
                        :
                            <button type='button' onClick={handleCreate} className="btn btn-warning">Add</button>
                    }
                </div>
            </div>
        </form>
    );
};

export default TaskComment;