import * as React from 'react';
import { useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { NavLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Skeleton } from '@mui/material';
import { useLogin } from '../context/LoginProvider';
import { taskColumns } from '../helpers/DisplayTableConstants';
import { APP_ROLES } from '../helpers/constants';
import { useApp } from '../context/AppProvider';

const TasksTable = () => {

    
    const [dataToShow, setDataToShow] = useState('allTasks');
    
    const { user } = useLogin()
    const {myPendingTasks} = useApp();
    
    const handleUpdateUpdate = (value) => {
        console.log(value)
    }

    return (
        <div style={{ height: 500, width: '100%' }}>
        <div className="row mb-3">
            <div className={`col-md-12`}>
                <h4>Tasks Assigned To Me</h4>
            </div>
            <div className="col-md-3 my-auto">
                <div className="dropdown">
                <FilterAltIcon  className="dropdown-toggle fs-4" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"/> <span>Filter</span>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <>
                        <span className="dropdown-item" onClick={() => setDataToShow('allTasks')} role={'button'}>All</span>
                        <span className="dropdown-item" onClick={() => setDataToShow('allPendingTasks')} role={'button'}>Pending</span>
                        <span className="dropdown-item" onClick={() => setDataToShow('allInProgressTasks')} role={'button'}>In Progress</span>
                        <span className="dropdown-item" onClick={() => setDataToShow('allCompletedTasks')} role={'button'}>Completed</span>
                    </>
                </ul>
                </div>
            </div>
        </div>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={myPendingTasks.Tasks ? myPendingTasks.Tasks[dataToShow] : []}
                    loading={myPendingTasks.Tasks ? false : true}
                    columns={taskColumns({updateHandler:handleUpdateUpdate})}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    onCellEditCommit={handleUpdateUpdate}
                    components={{Toolbar:GridToolbar}}
                />
            </div>
            {
                    user.role === APP_ROLES.general
                ?
                    null
                :
                    <div className="col-md-12 my-3">
                        <NavLink to={'/'} className="btn btn-danger me-3"><ArrowBackIcon/></NavLink>
                    </div>
            }
        </div>
    );
}


export default TasksTable;