import * as React from 'react';
import { useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DisplayTable from '../components/DisplayTable';
import { useApp } from '../context/AppProvider';
import { NavLink, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ReportTable = () => {

  const {filter} = useParams();
  
  const [dataToShow, setDataToShow] = useState(filter);
  const {fetch, setFetch, allInvoices} = useApp()

React.useEffect(() => {
  setDataToShow(filter);
}, [filter])
  return (
    <div style={{ height: 500, width: '100%' }}>
      <div className="row mb-3">
        <div className={`col-md-12`}>
            <h4>Reports</h4>
        </div>
        <div className="col-md-3 my-auto">
            <div className="dropdown">
            <FilterAltIcon  className="dropdown-toggle fs-4" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"/> <span>Filter</span>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <>
                    <span className="dropdown-item" onClick={() => setDataToShow('allInvoices')} role={'button'}>All</span>
                    <span className="dropdown-item" onClick={() => setDataToShow('allPayableInvoices')} role={'button'}>All Payable</span>
                    <span className="dropdown-item" onClick={() => setDataToShow('allRecievableInvoices')} role={'button'}>All Recievable</span>
                </>
            </ul>
            </div>
        </div>
      </div>
        <DisplayTable dataToShow={dataToShow} fetch={fetch} setFetch={setFetch} rows={allInvoices} showReports={true}/>
          <div className="col-md-12 my-3">
            <NavLink to={'/'} className="btn btn-danger me-3"><ArrowBackIcon/></NavLink>
          </div>
    </div>
  );
}


export default ReportTable;