import React from 'react';
import { useLogin } from '../context/LoginProvider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UserModal from '../modals/UserModal';

const ProfileLink = ({handleLogout}) => {

    const { user } = useLogin()

    return (
        <>
            <span className="dropdown">
                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <AccountCircleIcon/> {user.name.split(' ')[0]}
                </span>
                <div className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="navbarDropdown">
                    <span>
                        <UserModal id={user.id} title={'Updating Profile'} adminEdit={false}>
                            <span className="dropdown-item" role="button">Update Profile</span>
                        </UserModal>
                    </span>
                    <span>
                        <span className="dropdown-item" onClick={handleLogout} role="button">Logout</span>
                    </span>
                </div>
            </span>
        </>
    );
};

export default ProfileLink;