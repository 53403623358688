import { FormControl, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import { useApp } from '../context/AppProvider';
import { APP_ROLES } from '../helpers/constants';
import { notify } from '../helpers/functions';

const EditUser = ({id, editProfile=true}) => {

    // Database Information
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");
    const [active, setActive] = useState(true)

    const {fetch, setFetch} = useApp()
    const { user } = useLogin()

    // Loading States
    const [btnLoading, setBtnLoading] = useState(null);
    const [dataLoading, setDataLoading] = useState(null);

    // Retrieve Data
    useEffect(() => {
        setDataLoading(true);
        debtsAPI.get(`user/fetchById/${id}`)
        .then(res => {
            setDataLoading(false);
            if (res.data.success) {
                setFirstName(res.data.user.first_name)
                setLastName(res.data.user.last_name)
                setRole(res.data.user.role)
                setEmail(res.data.user.email)
                setActive(res.data.user.active)
            }
        }).catch(err => {
            console.log(err);
        })
    }, [id])

    // Handling Submission
    const handleSubmit = (e) => {
        
        e.preventDefault();

        const user_data = {
            first_name,
            last_name,
            password,
            role,
            email,
            active
        }

        setBtnLoading(true);
        console.log(id)
        debtsAPI.put(`/user/${id}`, user_data)
        .then(res => {
            setBtnLoading(false);
            if (res.data.success) {
                notify({message:res.data.message, success:true});
                setFetch(!fetch);
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false);
            console.log(err)
        })
        
    }
    console.log(active)
    return (
        <div>
            <form onSubmit={handleSubmit}>
                {
                        dataLoading
                    ?
                        <Skeleton width={'100%'} height={100}/>
                    :
                        <div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <TextField
                                        value={first_name}
                                        label='First Name'
                                        onChange={e => setFirstName(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <TextField
                                        value={last_name}
                                        label='Last Name'
                                        onChange={e => setLastName(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <TextField
                                        value={email}
                                        label='Email'
                                        onChange={e => setEmail(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <TextField
                                        type={'password'}
                                        value={password}
                                        label='Password'
                                        onChange={e => setPassword(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            {
                                    editProfile
                                ?
                                    <div className='row'>
                                        <div className="col-md-6 mb-3">
                                            <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                            <div>
                                                {
                                                    user.role === APP_ROLES.manager
                                                    ?
                                                        <>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                className='form-control'
                                                                id="demo-simple-select"
                                                                value={role}
                                                                label="Role"
                                                                onChange={(event) => setRole(event.target.value)}
                                                            >
                                                                <MenuItem value={'General Payable'}>General - Payable</MenuItem>
                                                                <MenuItem value={'General Recievable'}>General - Recievable</MenuItem>
                                                            </Select>
                                                        </>
                                                    :
                                                        <>
                                                            <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={role}
                                                            label="Role"
                                                            onChange={(event) => setRole(event.target.value)}
                                                            >
                                                                <MenuItem value={'Admin'}>Admin</MenuItem>
                                                                <MenuItem value={'Manager'}>Manager</MenuItem>
                                                                <MenuItem value={'General Payable'}>General - Payable</MenuItem>
                                                                <MenuItem value={'General Recievable'}>General - Recievable</MenuItem>
                                                            </Select>
                                                        </>
                                                }
                                            </div>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-check">
                                                <input className="form-check-input" onChange={()=>setActive(!active)} type="checkbox" value="" id="flexCheckChecked" checked={active} />
                                                <label className="form-check-label" for="flexCheckChecked">
                                                    Active
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null

                            }
                            <div className='row'>
                                <div className="col-md-12">
                                    {
                                            btnLoading
                                        ?
                                            <button className="btn btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="visually-hidden">Loading...</span>
                                            </button>
                                        :
                                            <button type='submit' className="btn btn-warning">Update</button>
                                    }
                                </div>
                            </div>
                        </div>           
                }
            </form>
        </div>
    );
};

export default EditUser;