import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { notify } from '../helpers/functions';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import { useApp } from '../context/AppProvider';


const InvoiceComment = ({data, handleClose}) => {

    const { user } = useLogin();
    const { setFetch, fetch } = useApp();

    // Database Information
    const [comment, setComment] = useState('');

    // Loading States
    const [btnLoading, setBtnLoading] = useState(null);


    // Handling Submission
    const handleSubmit = (e) => {
        
        e.preventDefault();

        setBtnLoading(true);
        debtsAPI.post(`/invoice/comment/${user.email}/${user.name}/${comment}/${data.id}`)
        .then(res => {
            setBtnLoading(false);
            if (res.data.success) {
                setComment('')    
                notify({message:res.data.message, success:true});
                setFetch(!fetch)
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false);
            console.log(err)
        })
        
    }
    
    return (
        <div>   
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <TextField  
                            value={comment}
                            label='Comment' 
                            onChange={e => setComment(e.target.value)} 
                            className="form-control"
                            multiline
                            fullWidth
                            maxRows={10}
                        />
                    </div>
                    <div className="col-md-12">
                        {
                                btnLoading
                            ?
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="visually-hidden">Loading...</span>
                                </button>
                            :
                                <button type='submit' className="btn btn-primary">Add Comment</button>
                        }
                    </div>
                </div>
            </form>
            <div className="col-md-12 d-flex justify-content-end">
                <button onClick={handleClose} className="btn btn-danger">Close</button>
            </div>
        </div>
    );
};

export default InvoiceComment;