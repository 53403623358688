import { Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../context/LoginProvider';

const InvoiceActionCard = ({title, children, path}) => {

    const navigate = useNavigate()
    const { setView } = useLogin()

    const handleClick = () => {
        setView(title.slice(0, -1))
        navigate(path)
    }

    return (
        <Card>
            <CardActions>
                <span>{children}</span>
            </CardActions>
            <CardActionArea onClick={handleClick}>
                <CardContent>
                    <Typography className='text-center' gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default InvoiceActionCard;