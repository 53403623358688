import { TextField } from '@mui/material';
import React, { useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import { useApp } from '../context/AppProvider';
import { notify } from '../helpers/functions';

const ClientDetails = ({row, handleClose, customerMode=false}) => {

    const [btnLoading, setBtnLoading] = useState(null);
    const {fetch, setFetch} = useApp()
    const {user} = useLogin()

    const handleApprove = () => {
        setBtnLoading(true);
        debtsAPI.put(`/client/${user.name}/${user.email}/${row.id}`)
        .then(res => {
            setBtnLoading(false)
            if (res.data.success) {
                notify({message:res.data.message, success:true})
                setTimeout(() => {
                setFetch(!fetch);
                }, 2000)
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false)
            console.log(err)
            notify({message:err.message, error:true})
        })
    }

    return (
        <div className="row">
            <h6>Contact Details</h6>
            <div className="col-md-6 mb-3">
                <TextField 
                    value={row.client_name}
                    label='Name of Client'  
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField 
                    value={row.postal_code}
                    label='Postal Code' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField 
                    value={row.physical_addr}
                    label='Physical Address' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField
                    value={row.land_line}
                    label='Land Line' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField 
                    value={row.phone_num}
                    label='Phone Number' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField
                    value={row.company_email}
                    label='Company Email' 
                    className="form-control"
                    disabled
                />
            </div>
            <h6>Tax Payer & Company Informtion</h6>
            <div className="col-md-6 mb-3">
                <TextField 
                    value={row.tpin_num}
                    label='TPIN Number' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField 
                    value={row.company_reg_num}
                    label='Company Registration Number' 
                    className="form-control"
                    disabled
                />
            </div>
            <h6>Location</h6>
            <div className="col-md-6 mb-3">
                <TextField
                    value={row.city}
                    label='City' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField 
                    value={row.county}
                    label='County' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField
                    value={row.country}
                    label='Country' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField 
                    value={row.province}
                    label='Province' 
                    className="form-control"
                    disabled
                />
            </div>
            <h6>Contact Person</h6>
            <div className="col-md-6 mb-3">
                <TextField
                    value={row.contact_name}
                    label='Contact Name' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField 
                    value={row.contact_phone_num}
                    label='Contact Phone Number' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField
                    value={row.contact_email}
                    label='Contact Email' 
                    className="form-control"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <TextField 
                    multiline={true}
                    value={row.payment_credit_terms}
                    label='Payment / Credit Terms' 
                    className="form-control"
                    disabled
                    maxRows={5}
                />
            </div>
            {
                    customerMode 
                ?
                    null
                :
                    <>
                        <h6>Bank Details</h6>
                        <div className="col-md-6 mb-3">
                            <TextField
                                value={row.bank_name}
                                label='Bank Name' 
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField 
                                value={row.bank_branch}
                                label='Bank Branch' 
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField
                                value={row.acc_name}
                                label='Account Name'  
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField
                                value={row.acc_num}
                                label='Account Number' 
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField
                                value={row.iban_num}
                                label='IBAN No.' 
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField
                                value={row.swift_code}
                                label='Swift Code' 
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField 
                                value={row.sort_code}
                                label='Sort Code' 
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <TextField
                                value={row.currency}
                                label='Currency' 
                                className="form-control"
                                disabled
                            />
                        </div>
                    </>
            }
            <div className="col-md-12 d-flex justify-content-end">
                {
                        btnLoading
                    ?
                        <button className="btn btn-warning" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="visually-hidden">Loading...</span>
                        </button>
                    :
                        <>
                            <button onClick={handleApprove} disabled={row.approved ? true : false} className={`btn btn-${row.approved ? 'success' : 'warning'} me-3`}>{row.approved ? 'Approved' : 'Approve'}</button>
                            <button onClick={handleClose} className="btn btn-danger">Close</button>
                        </>
                }
            </div>
        </div>
    );
};

export default ClientDetails;