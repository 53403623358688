import { Chip } from "@mui/material";
import CurrencyFormat from "react-currency-format";
import GeneralModal from "../modals/GeneralModal";
import InvoiceDetailsModal from "../modals/InvoiceDetailsModal";

const switchStatus = (status) => {
    if (status === 'Pending') {
        return <Chip className='text-danger' label={status} />
    } else if (status === 'Partially Paid') {
        return <Chip className='text-warning' label={status} />
    } else {
        return <Chip className='text-success' label={status} />
    }
  }

  const switchApproval = (status) => {
    if (status) {
        return <Chip className='text-success' label={'Approved'} />
    } else {
        return <Chip className='text-danger' label={'Not Approved'} />
    }
  }

  const switchTaskStatus = (status, isOtherTask) => {
    if (status === 'Pending') {
      return <Chip className='text-danger' label={status} />
    } else if (status === 'In Progress' || status === 'Await Approval') {
      return <Chip className='text-warning' label={status === 'Await Approval' && isOtherTask ? 'Awaiting Your Approval' : status} />
    }else {
        return <Chip className='text-success' label={status} />
    }
  }

  // General Columns
export const generalColumns = ({updateHandler}) =>  [
    { field: 'invoice_num', headerName: 'Invoice No.', width: 90 },
    { field: 'client_name', headerName: 'Supplier Name', width: 130 },
    {
      field: 'date_issued',
      headerName: 'Issued',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {new Date(params.row.date_issued).toDateString()}
          </>
        );
      }
    },
    {
      field: 'due_date',
      headerName: 'Due',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {new Date(params.row.due_date).toDateString()}
          </>
        );
      }
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <CurrencyFormat decimalScale={2} value={params.row.total} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
          </>
        );
      }
    },
    {
      field: 'paid',
      headerName: 'Paid',
      editable: false,
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <CurrencyFormat decimalScale={2} value={params.row.paid} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
          </>
        );
      }
    },
    {
      field: 'balance',
      headerName: 'Balance',
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <CurrencyFormat decimalScale={2} value={params.row.balance} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
          </>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              {
                switchStatus(params.row.status)
              }
          </>
        );
      }
    },
    {
        field: 'pay',
        headerName: 'Pay Status',
        editable: false,
        width: 150,
        renderCell: (params) => {
            return (
                <>
                    <button onClick={() => {
                        // Approved is ahead of paid. So, subtract to prevent miscalculation of new update value.
                        updateHandler(params.row.id, parseFloat(params.row.approved_amount) - parseFloat(params.row.paid))
                    }} disabled={params.row.approved ? true : false} 
                        className={`btn btn-${params.row.approved ? 'warning' : 'danger'} btn-sm`}>
                          {params.row.approved ? 'Pay' : 'Await Approval'}
                      </button>
                </>
            );
        }
    },
    {
        field: 'current_amount',
        headerName: 'Approved Amount',
        width: 170,
        renderCell: (params) => {
            return (
            <>
                <CurrencyFormat decimalScale={2} value={parseFloat(params.row.approved_amount) - parseFloat(params.row.paid) < 0 
                ? 0 : parseFloat(params.row.approved_amount) - parseFloat(params.row.paid)} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
            </>
            );
        }   
    }
  ];

// Default Columns - Recievable - Commented out date issued and vat type
export const defaultColumnsRecievable = [
  { field: 'invoice_num', headerName: 'Invoice No.', width: 90 },
  { field: 'sales_order_no', headerName: 'Sales No.', width: 130 },
  { field: 'client_name', headerName: 'Supplier Name', width: 130 },
  {
    field: 'due_date',
    headerName: 'Due',
    width: 150,
    renderCell: (params) => {
      return (
        <>
          {new Date(params.row.due_date).toDateString()}
        </>
      );
    }
  },
  {
    field: 'exvat',
    headerName: 'ExVAT',
    width: 90,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.exvat} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'vat',
    headerName: 'VAT',
    width: 90,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.vat} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 90,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.total} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'paid',
    headerName: 'Paid',
    editable: false,
    width: 90,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.paid} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'balance',
    headerName: 'Balance',
    width: 130,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.balance} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => {
      return (
        <>
            {
              switchStatus(params.row.status)
            }
        </>
      );
    }
  },
  {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <>
              <InvoiceDetailsModal fullScreen={true} title={'More Invoice Details'} row={params.row}>
                <button className="btn btn-warning btn-sm">Expand</button>
              </InvoiceDetailsModal>
          </>
        );
      }
    }
];

// Default Columns - Payable - Commented out date issued and vat type
export const defaultColumnsPayable = [
  { field: 'invoice_num', headerName: 'Invoice No.', width: 90 },
  { field: 'purchase_order_no', headerName: 'Purchase No.', width: 130 },
  { field: 'client_name', headerName: 'Supplier Name', width: 130 },
  {
    field: 'due_date',
    headerName: 'Due',
    width: 150,
    renderCell: (params) => {
      return (
        <>
          {new Date(params.row.due_date).toDateString()}
        </>
      );
    }
  },
  {
    field: 'exvat',
    headerName: 'ExVAT',
    width: 90,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.exvat} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'vat',
    headerName: 'VAT',
    width: 90,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.vat} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 90,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.total} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'paid',
    headerName: 'Paid',
    editable: false,
    width: 90,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.paid} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'balance',
    headerName: 'Balance',
    width: 130,
    renderCell: (params) => {
      return (
        <>
          <CurrencyFormat decimalScale={2} value={params.row.balance} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
        </>
      );
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => {
      return (
        <>
            {
              switchStatus(params.row.status)
            }
        </>
      );
    }
  },
  {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <>
              <InvoiceDetailsModal fullScreen={true} title={'More Invoice Details'} row={params.row}>
                <button className="btn btn-warning btn-sm">Expand</button>
              </InvoiceDetailsModal>
          </>
        );
      }
    }
];

// Admin Columns
export const specialColumns = [
    { field: 'invoice_num', headerName: 'Invoice No.', width: 90 },
    { field: 'client_name', headerName: 'Supplier Name', width: 130 },
    {
      field: 'date_issued',
      headerName: 'Issued',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {new Date(params.row.date_issued).toLocaleDateString()}
          </>
        );
      }
    },
    {
      field: 'due_date',
      headerName: 'Due',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {new Date(params.row.due_date).toLocaleDateString()}
          </>
        );
      }
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <CurrencyFormat decimalScale={2} value={params.row.total} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
          </>
        );
      }
    },
    {
      field: 'paid',
      headerName: 'Paid',
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <CurrencyFormat decimalScale={2} value={params.row.paid} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
          </>
        );
      }
    },
    {
      field: 'balance',
      headerName: 'Balance',
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <CurrencyFormat decimalScale={2} value={params.row.balance} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
          </>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              {
                switchStatus(params.row.status)
              }
          </>
        );
      }
    },
    {
        field: 'update',
        headerName: 'Approve',
        editable: false,
        width: 100,
        renderCell: (params) => {
            return (
            <>
                <GeneralModal openModal={!params.row.approved} row={params.row} amount={params.row.total} balance={params.row.balance} title={'Approve Invoice Payment'}>
                    <button 
                      disabled={params.row.approved ? true : false} 
                      className={`btn btn-${params.row.approved ? 'danger' : 'warning'} btn-sm`}>
                        {params.row.approved ? 'Await Pay' : 'Approve'}
                    </button>
                </GeneralModal>
            </>
            );
        }
    },
    {
        field: 'approved_amount',
        headerName: 'Total Approved Amount',
        width: 170,
        renderCell: (params) => {
            return (
            <>
                <CurrencyFormat decimalScale={2} value={params.row.approved_amount} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
            </>
            );
        }   
    },
  ];

  // Reports Column
  export const reportColumns = [
    { field: 'invoice_num', headerName: 'Invoice No.', width: 200 },
    { field: 'client_name', headerName: 'Supplier Name', width: 200 },
    {
      field: 'paid',
      headerName: 'Total Paid',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <CurrencyFormat decimalScale={2} value={params.row.paid} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
          </>
        );
      }
    },
    {
      field: 'approved_by',
      headerName: 'Approved By',
      width: 200,
      renderCell: (params) => {
        console.log(params.row)
        return (
          <>
            {params.row.ApprovalLogs.length > 0 ? params.row.ApprovalLogs[0].approved_by : 'None'}
          </>
        );
      }
    },
    {
      field: 'paid_by',
      headerName: 'Paid By',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.PaidLogs.length > 0 ? params.row.PaidLogs[0].paid_by : 'None'}
          </>
        );
      }
    },
    {
        field: 'expand',
        headerName: 'Action',
        editable: false,
        width: 100,
        renderCell: (params) => {
            return (
            <>
                <GeneralModal modalFor={'Reports'} fullScreen={true} reportsRows={params.row.PaidLogs.concat(params.row.ApprovalLogs)} title={'Invoice Report'}>
                    <button 
                      className={`btn btn-warning btn-sm`}>
                        Expand
                    </button>
                </GeneralModal>
            </>
            );
        }
    }
  ];


  // Columns for all suppliers
  export const supplierColumns = [
    { field: 'client_name', headerName: 'Supplier Name', width: 180 },
    { field: 'physical_addr', headerName: 'Physical Addr', width: 180 },
    { field: 'tpin_num', headerName: 'TPIN Number', width: 180 },
    { field: 'country', headerName: 'Country', width: 180 },
    { field: 'contact_phone_num', headerName: 'Contact Phone No.', width: 180 },
    { field: 'bank_name', headerName: 'Bank Name', width: 180 },
    {
      field: 'approved',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              {
                switchApproval(params.row.approved)
              }
          </>
        );
      }
    },
    {
        field: 'expand',
        headerName: 'Action',
        editable: false,
        width: 100,
        renderCell: (params) => {
            return (
            <>
                <GeneralModal modalFor={'SupplierDetails'} row={params.row} fullScreen={true} title={`Supplier MGC-${params.row.id} Details`}>
                    <button 
                      className={`btn btn-warning btn-sm`}>
                        Expand
                    </button>
                </GeneralModal>
            </>
            );
        }
    }
  ];

  // Columns for all customers
  export const customerColumns = [
    { field: 'client_name', headerName: 'Customer Name', width: 180 },
    { field: 'physical_addr', headerName: 'Physical Addr', width: 180 },
    { field: 'tpin_num', headerName: 'TPIN Number', width: 180 },
    { field: 'country', headerName: 'Country', width: 180 },
    { field: 'contact_phone_num', headerName: 'Contact Phone No.', width: 180 },
    {
      field: 'approved',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              {
                switchApproval(params.row.approved)
              }
          </>
        );
      }
    },
    {
        field: 'expand',
        headerName: 'Action',
        editable: false,
        width: 100,
        renderCell: (params) => {
            return (
            <>
                <GeneralModal modalFor={'ClientDetails'} customerMode={true} row={params.row} fullScreen={true} title={`Customer MGC-${params.row.id} Details`}>
                    <button 
                      className={`btn btn-warning btn-sm`}>
                        Expand
                    </button>
                </GeneralModal>
            </>
            );
        }
    }
  ];

  // Colums for My Tasks
  export const taskColumns = ({updateHandler}) => [
    {
      field: 'task_description',
      headerName: 'Description',
      width: 300,
      renderCell: (params) => {
        return (
          <>
              <div className="row">
                <div className="col-md-12">
                  {params.row.task_description}
                </div>
              </div>
          </>
        );
      }
    },
    { field: 'assigner_name', headerName: 'Assigned By', width: 180 },
    {
      field: 'task_start',
      headerName: 'Started',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              <div className="row">
                <div className="col-md-12">
                  { params.row.task_start ? params.row.task_start : 'Not Started'}
                </div>
              </div>
          </>
        );
      }
    },
    {
      field: 'task_end',
      headerName: 'Required By',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              <div className="row">
                <div className="col-md-12">
                  {new Date(params.row.task_end).toLocaleString()}
                </div>
              </div>
          </>
        );
      }
    },
    {
      field: 'task_finished_on',
      headerName: 'Finished',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              <div className="row">
                <div className="col-md-12">
                  { params.row.task_finished_on ? params.row.task_finished_on : <span className="text-danger fw-bold">Pending</span>}
                </div>
              </div>
          </>
        );
      }
    },
    {
      field: 'completion_confirmed',
      headerName: 'Approved As Complete',
      width: 170,
      renderCell: (params) => {
        return (
          <>
              <div className="row">
                <div className="col-md-12">
                  { params.row.completion_confirmed ? <span className="text-success fw-bold">Yes</span> : <span className="text-danger fw-bold">No</span>}
                </div>
              </div>
          </>
        );
      }
    },
    {
      field: 'task_status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => {
        return (
          <>
              {switchTaskStatus(params.row.task_status)}
          </>
        );
      }
    },
    {
        field: 'expand',
        headerName: 'Action',
        editable: false,
        width: 150,
        renderCell: (params) => {
            return (
              <GeneralModal openModal={(params.row.task_status === 'Complete' || params.row.task_status === 'Await Approval') ? false : true} title={'Update Task'} modalFor={'TaskUpdate'} row={params.row}>
                <button 
                  className={`btn btn-warning btn-sm`}
                  disabled={(params.row.task_status === 'Complete' || params.row.task_status === 'Await Approval') ? true : false}
                >
                    Update
                </button>
              </GeneralModal>
            );
        }
    },
    {
      field: 'comment',
      headerName: 'Comment',
      editable: false,
      width: 150,
      renderCell: (params) => {
          return (
            <GeneralModal openModal={(params.row.task_status === 'Complete' || params.row.task_status === 'Await Approval') ? false : true} title={'Add Comment to Task'} modalFor={'TaskComment'} id={params.row.id}>
              <button 
                className={`btn btn-warning btn-sm`}
                disabled={(params.row.task_status === 'Complete' || params.row.task_status === 'Await Approval') ? true : false}
              >
                  Add Comment
              </button>
            </GeneralModal>
          );
      },
  },
  {
      field: 'show_comment',
      headerName: 'Comments',
      editable: false,
      width: 150,
      renderCell: (params) => {
          return (
            <GeneralModal data={params.row} row={params.row.TaskComments} fullScreen={true} title={'Comments'} modalFor={'TaskCommentsTable'} id={params.row.id}>
              <button 
                className={`btn btn-warning btn-sm`}
              >
                  View Comments
              </button>
            </GeneralModal>
          );
      },
  }
  ];

  // Columns for Others Tasks
  export const othersTasksColumns = ({updateHandler}) => [
    {
      field: 'task_description',
      headerName: 'Description',
      width: 300,
      renderCell: (params) => {
        return (
          <>
              <div className="row">
                <div className="col-md-12">
                  {params.row.task_description}
                </div>
              </div>
          </>
        );
      }
    },
    { field: 'assignee_name', headerName: 'Assigned To', width: 180 },
    {
      field: 'task_start',
      headerName: 'Started',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              <div className="row">
                <div className="col-md-12">
                  { params.row.task_start ? params.row.task_start : 'Not Started'}
                </div>
              </div>
          </>
        );
      }
    },
    {
      field: 'task_end',
      headerName: 'Required By',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              <div className="row">
                <div className="col-md-12">
                  {new Date(params.row.task_end).toLocaleString()}
                </div>
              </div>
          </>
        );
      }
    },
    {
      field: 'task_finished_on',
      headerName: 'Finished',
      width: 150,
      renderCell: (params) => {
        return (
          <>
              <div className="row">
                <div className="col-md-12">
                  { params.row.task_finished_on ? params.row.task_finished_on : <span className="text-danger fw-bold">Pending</span>}
                </div>
              </div>
          </>
        );
      }
    },
    {
      field: 'task_status',
      headerName: 'Status',
      width: 300,
      renderCell: (params) => {
        return (
          <>
              {switchTaskStatus(params.row.task_status, true)}
          </>
        );
      }
    },
    {
      field: 'action',
        headerName: 'Task Complete',
        editable: false,
        width: 150,
        renderCell: (params) => {
            return (
                <button 
                  disabled={params.row.completion_confirmed ? true : params.row.task_status !== 'Await Approval' ? true : false}
                  className={`btn btn-${params.row.completion_confirmed ? 'success' : 'warning'} btn-sm`}
                  onClick={() => updateHandler(params.row.id, 'Complete')}
                >
                   {params.row.completion_confirmed ? 'Done' : 'Mark As Done'} 
                </button>
            );
        }
    },
    {
      field: 'addComment',
      headerName: 'Comment',
      editable: false,
      width: 150,
      renderCell: (params) => {
          return (
            <GeneralModal openModal={(params.row.task_status === 'Complete' || params.row.task_status === 'Await Approval') ? false : true} title={'Add Comment to Task'} modalFor={'TaskComment'} id={params.row.id}>
              <button 
                disabled={(params.row.task_status === 'Complete' || params.row.task_status === 'Await Approval') ? true : false}
                className={`btn btn-warning btn-sm`}
              >
                  Add Comment
              </button>
            </GeneralModal>
          );
      }
    },
    {
        field: 'expand',
        headerName: 'Comments',
        editable: false,
        width: 150,
        renderCell: (params) => {
            return (
              <GeneralModal data={params.row} row={params.row.TaskComments} fullScreen={true} title={'Comments'} modalFor={'TaskCommentsTable'} id={params.row.id}>
                <button 
                  className={`btn btn-warning btn-sm`}
                >
                    View
                </button>
              </GeneralModal>
            );
        }
    },
  ];