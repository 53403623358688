import React from 'react';

const JobManagerDetails = ({companyManager, clientManager}) => {
    return (
        <>
            <div className="col-md-4 my-1">
                <h5 className="text-secondary">Company Job Manager Details</h5>
                <p className='mb-0'><span className="fw-bold">Name: </span>{companyManager.name}</p>
                <p className='mb-0'><span className="fw-bold">Email: </span>{companyManager.email}</p>
            </div>
            <div className="col-md-4 my-1">
                <h5 className="text-secondary">Client Job Manager Details</h5>
                <p className='mb-0'><span className="fw-bold">Name: </span>{clientManager.name}</p>
                <p className='mb-0'><span className="fw-bold">Email: </span>{clientManager.email}</p>
                <p className='mb-0'><span className="fw-bold">Phone: </span>{clientManager.phone}</p>
            </div>
        </>
    );
};

export default JobManagerDetails;