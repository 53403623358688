import { Skeleton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useLogin } from '../context/LoginProvider';
import { APP_ROLES } from '../helpers/constants';
import debtsAPI from '../api/debtsAPI';
import { notify } from '../helpers/functions';
import { useApp } from '../context/AppProvider';
import { generalColumns } from '../helpers/DisplayTableConstants';
import { DataGrid } from '@mui/x-data-grid';

const SearchResults = () => {

    const { keyword } = useParams()
    const { user } = useLogin()
    const { fetch, setFetch } = useApp()

    const setDefaultInvoice = () => {
        if (user.role === APP_ROLES.admin || user.role === APP_ROLES.manager) {
            return 'allInvoices'
        } else if (user.role === APP_ROLES.generalPayable) {
            return 'allPayableInvoices'
        } else {
            return 'allRecievableInvoices'
        }
    }
    
    const [dataLoading, setDataLoading] = useState(true);
    let [rows, setRows] = useState([])

    console.log(keyword)
    
    useEffect(() => {
        setDataLoading(true)
        debtsAPI.get(`/invoice/search/${keyword}`)
        .then(res => {
            if (res.data.success) {
                console.log(res.data)
                setRows(res.data.results);
                setDataLoading(false)
            } else {
                console.log('failed')
                notify({message: res.data.message, error: true})
            }
        }).catch(err => {
            console.log('error')
            notify({message: err.message, error:true})
        })
    }, [keyword])
    
    // Update handler
    const handleProcess = (id, amount) => {
        setDataLoading(true);
        debtsAPI.put(`/invoice/${id}/${amount}/${user.email}/${user.name}`)
        .then(res => {
        setDataLoading(false)
        if (res.data.success) {
            notify({message:res.data.message, success:true})
            setTimeout(() => {
              setFetch(!fetch);
            }, 2000)
        } else {
            notify({message:res.data.message, error:true})
        }
        }).catch(err => {
            setDataLoading(false)
            console.log(err)
        })
    }

    //console.log(rows[dataToShow])
    return (
        <div>
            {
                    dataLoading
                ?
                    <Skeleton width={'100%'} height={400} />
                :
                    <div style={{ height: 500, width: '100%' }}>
                        <div className="row mb-3">
                        <div className="col-md-12 d-flex mb-3">
                            <NavLink to={`/invoices/${setDefaultInvoice()}`} className="btn btn-danger me-3">Back</NavLink>
                            <h4>{rows ? rows.length : 0} Search Results</h4>
                        </div>
                        </div>
                        {
                                rows && !dataLoading
                            ?
                                // <>{console.log(rows)}</>
                                <div style={{ height: 300, minWidth: '500px' }}>
                                    <DataGrid
                                        rows={rows}
                                        columns={generalColumns({updateHandler:handleProcess})}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                    />
                                </div>
                            :
                                null
                        }
                    </div>
            }
        </div>
    );
};

export default SearchResults;