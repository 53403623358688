import * as React from 'react';
import { useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useApp } from '../context/AppProvider';
import { NavLink, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { customerColumns } from '../helpers/DisplayTableConstants';

const CustomerTable = () => {

  const {filter} = useParams();
  
  const [dataToShow, setDataToShow] = useState(filter);
  const {customers} = useApp()

React.useEffect(() => {
  setDataToShow(filter);
}, [filter])
  return (
    <div style={{ height: 500, width: '100%' }}>
      <div className="row mb-3">
        <div className={`col-md-12`}>
            <h4>Customers</h4>
        </div>
        <div className="col-md-3 my-auto">
            <div className="dropdown">
            <FilterAltIcon  className="dropdown-toggle fs-4" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"/> <span>Filter</span>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <>
                    <span className="dropdown-item" onClick={() => setDataToShow('customers')} role={'button'}>All Suppliers</span>
                    <span className="dropdown-item" onClick={() => setDataToShow('approvedCustomers')} role={'button'}>Approved Suppliers</span>
                    <span className="dropdown-item" onClick={() => setDataToShow('UnApprovedCustomers')} role={'button'}>UnApproved Suppliers</span>
                </>
            </ul>
            </div>
        </div>
      </div>
            <DataGrid
                rows={customers[dataToShow] ? customers[dataToShow] : []}
                columns={customerColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                components={{Toolbar:GridToolbar}}
                loading={customers[dataToShow] ? false : true}
            />
          <div className="col-md-12 my-3">
            <NavLink to={'/'} className="btn btn-danger me-3"><ArrowBackIcon/></NavLink>
          </div>
    </div>
  );
}


export default CustomerTable;