import React from 'react';
import StatCard from '../components/StatCard';
import { useLogin } from '../context/LoginProvider';
import { useApp } from '../context/AppProvider';
import { APP_ROLES } from '../helpers/constants';
import InvoiceLink from '../links/InvoiceLink';

const Home = () => {

    const {allInvoices} = useApp()
    const {user, view} = useLogin();

    return (
        <>
        <div className='row'>
            <div className="col-md-12">
                <h4>Dashboard - {view}</h4>
            </div>
        </div>
        {
            <>
                {
                    user.role !== APP_ROLES.generalRecievable && view === 'Payable'
                    ?
                        <>
                            <div className='row mb-3'>
                                <div className="col-md-4 mb-3">
                                    <StatCard title={'Paid'} path={'paidPayableInvoices'} pathName={'Records'} value={allInvoices.paidDebtsPayableSum} colorName={'green'}/>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <StatCard title={'Partially Paid'} path={'partiallyPaidPayableInvoices'} pathName={'Records'} value={allInvoices.partiallyPaidDebtsPayableSum} colorName={''}/>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <StatCard title={'Unpaid'} path={'pendingPayableInvoices'} pathName={'Records'} value={allInvoices.unPaidDebtsPayableSum} colorName={'red'}/>
                                </div>
                            </div>
                        </>
                    :
                        null
                }
                {
                    user.role !== APP_ROLES.generalPayable && view === 'Recievable'
                    ?
                        <>
                            <div className='row'>
                                <div className="col-md-4 mb-3">
                                    <StatCard title={'Paid'} path={'paidRecievableInvoices'} pathName={'Records'} value={allInvoices.paidDebtsRecievableSum} colorName={'green'}/>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <StatCard title={'Partially Paid'} path={'partiallyPaidRecievableInvoices'} pathName={'Records'} value={allInvoices.partiallyPaidDebtsRecievableSum} colorName={''}/>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <StatCard title={'Unpaid'} path={'pendingRecievableInvoices'} pathName={'Records'} value={allInvoices.unPaidDebtsRecievableSum} colorName={'red'}/>
                                </div>
                            </div>
                        </>
                    :
                        null
                }
            </>
        }
        <div className="row">
            <InvoiceLink/>
        </div>
        </>
    );
};

export default Home;