import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import GeneralModal from '../modals/GeneralModal';
  
  const columns = [
    { field: 'comment', headerName: 'Comment', width: 500, editable: false, 
      renderCell: (params) => {
        return (
          <>
            <GeneralModal modalFor='Message' data={params.row.comment} title={'Comment'}>
              <span role={'button'}>{params.row.comment}</span>
            </GeneralModal>
          </>
        )
      }
    },
    { field: 'commentBy', headerName: 'Comment By', width: 500, editable: false },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 300,
      renderCell: (params) => {
        return (
          <>
              {new Date(params.row.createdAt).toLocaleString()}
          </>
        );
      }
    }
  ];

  const switchTaskStatus = (status, isOtherTask) => {
    if (status === 'Pending') {
      return <Chip className='text-danger' label={status} />
    } else if (status === 'In Progress' || status === 'Await Approval') {
      return <Chip className='text-warning' label={status === 'Await Approval' && isOtherTask ? 'Awaiting Your Approval' : status} />
    }else {
        return <Chip className='text-success' label={status} />
    }
  }

const TaskCommentsTable = ({rows, data, handleClose}) => {
    
    return (
        <div>
            <div className="row">
              <h4>Task Details</h4>
              <div className="col-md-4">
                <h6 className="text-secondary">Description</h6>
                <p>{data.task_description}</p>
              </div>
              <div className="col-md-4">
                <h6 className="text-secondary">Assigned By</h6>
                <p>{data.assigner_name}</p>
              </div>
              <div className="col-md-4">
                <h6 className="text-secondary">Assigned To</h6>
                <p>{data.assignee_name}</p>
              </div>
              <div className="col-md-4">
                <h6 className="text-secondary">Assigned On</h6>
                <p>{new Date(data.task_end).toLocaleString()}</p>
              </div>
              <div className="col-md-4">
                <h6 className="text-secondary">Started On</h6>
                <p>{ data.task_start ? data.task_start : 'Not Started'}</p>
              </div>
              <div className="col-md-4">
                <h6 className="text-secondary">Finished On</h6>
                <p>{ data.task_finished_on ? data.task_finished_on : <span className="text-danger fw-bold">Pending</span>}</p>
              </div>
              <div className="col-md-4">
                <h6 className="text-secondary">Status</h6>
                <p>{switchTaskStatus(data.task_status)}</p>
              </div>
            </div>
            <div style={{ height: 300, minWidth: '500px' }}>
                <DataGrid
                    rows={rows}
                    loading={rows ? false : true}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end mt-2">
                <button onClick={handleClose} className="btn btn-danger">
                  Close
                </button>
              </div>
            </div>
        </div>
    );
};

export default TaskCommentsTable;