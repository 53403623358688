import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InvoiceApproval from '../forms/InvoiceApproval';
import ReportsDisplayTable from '../tables/ReportsDisplayTable';
import ClientDetails from '../PopupContents/ClientDetails';
import Task from '../forms/Task';
import TaskUpdate from '../forms/TaskUpdate';
import TaskComment from '../forms/TaskComment';
import Message from '../components/Message';
import TaskCommentsTable from '../tables/TaskCommentsTable';

const GeneralModal = ({children, title, row, amount, data, id, reportsRows=null, balance, openModal=true, fullScreen=false, modalFor='ApprovalForm', customerMode=false}) => {
        const [open, setOpen] = useState(false);

        const handleClickOpen = () => {
            if (openModal) {
                setOpen(true);
            }
        };

        const handleClose = () => {
            setOpen(false);
        };

        return (
            <div>
                <span onClick={handleClickOpen}>
                    {children}
                </span>
                <Dialog
                    open={open}
                    scroll={'paper'}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullScreen={fullScreen}
                >
                    <span className='position-relative bg-dark w-100'>
                        <span onClick={handleClose} className="position-absolute end-0 m-3" style={{cursor:'pointer'}}>
                            <CloseIcon />
                        </span>
                    </span>
                    <DialogTitle id="alert-dialog-title">
                    {title}
                    </DialogTitle>
                    <DialogContent>
                        <div className="mt-1">
                            {/* Approval Form */}
                            {
                                    modalFor === 'ApprovalForm'
                                ?
                                    <InvoiceApproval amount={amount} balance={balance} invoice={row}/>
                                : 
                                    null
                            }
                            {/* Reports Table */}
                            {
                                    modalFor === 'Reports'
                                ?
                                    <ReportsDisplayTable handleClose={handleClose} rows={reportsRows}/>
                                :
                                    null
                            }
                            {/* Clients */}
                            {
                                    modalFor === 'ClientDetails'
                                ?
                                    <ClientDetails customerMode={customerMode} handleClose={handleClose} row={row}/>
                                :
                                    null
                            }
                            {/* Task */}
                            {
                                    modalFor === 'TaskCreation'
                                ?
                                    <Task />
                                :
                                    null
                            }
                            {/* Task Update */}
                            {
                                    modalFor === 'TaskUpdate'
                                ?
                                    <TaskUpdate row={row} />
                                :
                                    null
                            }
                            {/* Task Comment  */}
                            {
                                    modalFor === 'TaskComment'
                                ?
                                    <TaskComment id={id} />
                                :
                                    null
                            }
                            {/* Tasks Comments Table */}
                            {
                                    modalFor === 'TaskCommentsTable'
                                ?
                                    <TaskCommentsTable handleClose={handleClose} rows={row} data={data}/>
                                :
                                    null
                            }
                            {/* Message */}
                            {
                                    modalFor === 'Message'
                                ?
                                    <Message message={data}/>
                                :
                                    null
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

    );
};

export default GeneralModal;