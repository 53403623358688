import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import Invoice from '../forms/Invoice';
import CloseIcon from '@mui/icons-material/Close';

const InvoiceModal = ({children, title, isPayable=true, setFetch, fetch}) => {
        const [open, setOpen] = useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        return (
            <div>
                <span onClick={handleClickOpen}>
                    {children}
                </span>
                <Dialog
                    open={open}
                    scroll={'paper'}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <span className='position-relative bg-dark w-100'>
                        <span onClick={handleClose} className="position-absolute end-0 m-3" style={{cursor:'pointer'}}>
                            <CloseIcon />
                        </span>
                    </span>
                    <DialogTitle id="alert-dialog-title">
                    {title}
                    </DialogTitle>
                    <DialogContent>
                        <div className="mt-1">
                            {
                                    isPayable
                                ?
                                    <Invoice handleClose={handleClose} invoice_type={'Payable'} fetch={fetch} setFetch={setFetch}/>
                                :
                                    <Invoice handleClose={handleClose} fetch={fetch} setFetch={setFetch}/>
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

    );
};

export default InvoiceModal;