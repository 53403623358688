import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { renderPrice, switchStatus } from '../helpers/functions';
import JobManagerDetails from './JobManagerDetails';

const InvoicePreview = ({row, handleClose}) => {

    // Convert Stringified Data
    const companyJobManager = JSON.parse(row.company_job_manager_details)
    const clientJobManager = JSON.parse(row.client_job_manager_details)

    // Invoice Type State
    const [invoice_type, setInvoiceType] = useState('')

    useEffect(() => {
        setInvoiceType(row.invoice_type)
    }, [row])
    console.log(invoice_type)
    return (
        <div className='row'>
            <div className="col-md-4 my-1">
                <h5 className="text-secondary">Invoice Status</h5>
                {switchStatus(row.status)}
            </div>
            <div className="col-md-4 my-1">
                <h5 className="text-secondary">Invoice Number Details</h5>
                {
                        invoice_type === 'Recievable'
                    ?
                        <p className='mb-0'><span className="fw-bold">Sales Order No: </span>{row.sales_order_no}</p>
                    :
                        <p className='mb-0'><span className="fw-bold">Purchase Order Order No: </span>{row.purchase_order_no}</p>
                }
                <p className='mb-0'><span className="fw-bold">Invoice No: </span>{row.invoice_num}</p>
            </div>
            <div className="col-md-4 my-1">
                <h5 className="text-secondary">Invoice Time Details</h5>
                <p className='mb-0'><span className="fw-bold">Issued: </span>{new Date(row.date_issued).toDateString()}</p>
                <p className='mb-0'><span className="fw-bold">Due: </span>{new Date(row.due_date).toDateString()}</p>
            </div>
            <div className="col-md-4 my-1">
                <h5 className="text-secondary">Invoice Monetary Details</h5>
                <p className='mb-0'><span className="fw-bold">ExVAT: </span>{renderPrice(row.exvat)}</p>
                <p className='mb-0'><span className="fw-bold">VAT: </span>{renderPrice(row.vat)}</p>
                <p className='mb-0'><span className="fw-bold">Total: </span>{renderPrice(row.total)}</p>
                <p className='mb-0'><span className="fw-bold">Paid: </span>{renderPrice(row.paid)}</p>
                <p className='mb-0'><span className="fw-bold">Balance: </span>{renderPrice(row.balance)}</p>
            </div>
            {
                    invoice_type === 'Recievable'
                ?
                    <JobManagerDetails clientManager={clientJobManager} companyManager={companyJobManager}/>
                :
                    null
            }
            <div className="col-md-4 my-1">
                <h5 className="text-secondary">Client Contact Person Details</h5>
                <p className='mb-0'><span className="fw-bold">Name: </span>{row.contact_name}</p>
                <p className='mb-0'><span className="fw-bold">Email: </span>{row.contact_email}</p>
                <p className='mb-0'><span className="fw-bold">Phone: </span>{row.contact_phone}</p>
            </div>
            <div className="col-md-4 my-1">
                <h5 className="text-secondary">Client Details</h5>
                <p className='mb-0'><span className="fw-bold">Client Name: </span>{row.client_name}</p>
                <p className='mb-0'><span className="fw-bold">Client TPIN: </span>{row.client_tpin}</p>
            </div>
            <div className="col-md-12 d-flex justify-content-end">
                <button onClick={handleClose} className="btn btn-danger">Close</button>
            </div>
        </div>
    );
};

export default InvoicePreview;