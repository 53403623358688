import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLogin } from '../context/LoginProvider';
import { loadAnimation } from '../helpers/functions';

const PaymentLink = ({totalUnpaidPayables, totalUnpaidRecievables}) => {

    const { view } = useLogin();

    return (
        <>
            <li className="nav-item">
                <NavLink to={`/invoices/Payments`} className="nav-link">
                    PAYMENTS <span className="badge bg-danger">
                        {
                            (view === 'Payable' ? totalUnpaidPayables > 9 ? "9+" : (totalUnpaidPayables ? totalUnpaidPayables : '0') 
                            : totalUnpaidRecievables > 9 ? "9+" : (totalUnpaidRecievables ? totalUnpaidRecievables : '0'))
                            || loadAnimation()
                        }
                        </span></NavLink>
            </li>
        </>
    );
};

export default PaymentLink;