import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../pages/Login';

const DefaultNavigation = () => {
    return (
        <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path='*' element={<Login/>}/>
        </Routes>
    );
};

export default DefaultNavigation;