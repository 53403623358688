import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { notify } from '../helpers/functions';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import CurrencyFormat from 'react-currency-format';
import { useApp } from '../context/AppProvider';
import JobManager from './JobManager';


const Invoice = ({invoice_type='Recievable', setFetch, fetch, handleClose}) => {

    const {user} = useLogin()
    const { customers, suppliers } = useApp()

    const approvedCustomers = customers['approvedCustomers'];
    const approvedSuppliers = suppliers['approvedSuppliers'];

    // Reset Object State
    const [objState, setObjState] = useState(true);

    // Database Information
    const [client, setClient] = useState(null);
    const [invoice_num, setInvoiceNum] = useState(null);
    const [date_issued, setDateIssued] = useState(null);
    const [due_date, setDueDate] = useState(null);
    const [exvat, setExvat] = useState(0);
    const [vat_type, seVatType] = useState("")
    const [sales_order_no, setSalesOrderNo] = useState(null)
    const [purchase_order_no, setPurchaseOrderNo] = useState(null)
    const [client_job_manager_name, setClientJobManagerName] = useState(null)
    const [client_job_manager_email, setClientJobManagerEmail] = useState(null)
    const [client_job_manager_phone, setClientJobManagerPhone] = useState(null)
    const [company_job_manager, setCompanyJobManager] = useState(null)

    // Loadind States
    const [btnLoading, setBtnLoading] = useState(null);

    // Handle Reset
    const handleReset = () => {
        setExvat(0)
        setDateIssued(null)
        setDueDate(null)
        setInvoiceNum('')
        setPurchaseOrderNo('')
        setSalesOrderNo('')
        setCompanyJobManager(null)
        setClient(null)
        setClientJobManagerEmail('')
        setClientJobManagerName('')
        setClientJobManagerPhone('')
        setObjState(!objState)
        seVatType('')
    }

    // Handling Submission
    const handleSubmit = (e) => {
        
        e.preventDefault();

        // Perform some validation
        if (!client || !date_issued || !due_date) {
            notify({message: 'All fields are required', error:true});
            return;
        }

        if (!parseFloat(exvat)) {
            notify({message: 'Exvat should be a float value.', error:true});
            return;
        }

        // Calculate total
        let vat_total = 0 
        
        if (vat_type === 'Deductable') {
            vat_total = parseFloat(exvat) * 0.16
        }
            
        const total_pending = parseFloat(exvat) + parseFloat(vat_total)

        const clientJobManagerData = {
            name: client_job_manager_name,
            email: client_job_manager_email,
            phone: client_job_manager_phone
        }

        const companyJobManagerData = {
            name: company_job_manager.first_name + company_job_manager.last_name,
            email: company_job_manager.email
        }

        const invoice_data = {
            client_name: client.client_name,
            client_tpin: client.tpin_num,
            invoice_num,
            date_issued,
            due_date,
            exvat: parseFloat(exvat).toFixed(2),
            vat: parseFloat(vat_total).toFixed(2),
            status: 'Pending',
            total: parseFloat(total_pending).toFixed(2),
            invoice_type,
            balance: 0,
            paid: 0,
            contact_name: client.contact_name,
            contact_email: client.contact_email,
            contact_phone: client.contact_phone_num,
            vat_type,
            client_job_manager_details: JSON.stringify(clientJobManagerData),
            company_job_manager_details: JSON.stringify(companyJobManagerData)
        }

        if (invoice_type === 'Recievable') {
            invoice_data.sales_order_no = sales_order_no;
        } else {
            invoice_data.purchase_order_no = purchase_order_no;
        }

        setBtnLoading(true);
        debtsAPI.post(`/invoice/${user.email}/${user.name}`, invoice_data)
        .then(res => {
            setBtnLoading(false);
            if (res.data.success) {
                notify({message:res.data.message, success:true});
                
                handleReset()
                setFetch(!fetch)
                
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false);
            console.log(err)
        })
        
    }
    
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12 mb-3 mt-2">
                        <Autocomplete
                            key={objState}
                            id="country-select-demo"
                            options={invoice_type === 'Recievable' ? approvedCustomers : approvedSuppliers}
                            autoHighlight
                            getOptionLabel={(option) => {
                                return option.client_name;
                            }}
                            onChange={(e, client) => setClient(client)}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.client_name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    className='w-100'
                                    required
                                    {...params}
                                    label={invoice_type === 'Payable' ? 'Choose A Supplier' : 'Choose A Customer'}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            )}
                        />
                    </div>
                    {
                            invoice_type === 'Recievable'
                        ?
                            <JobManager 
                                setClientManagerEmail={setClientJobManagerEmail}
                                setClientManagerName={setClientJobManagerName}
                                setClientManagerPhone={setClientJobManagerPhone}
                                setCompanyJobManager={setCompanyJobManager}
                                name={client_job_manager_name}
                                email={client_job_manager_email}
                                phone={client_job_manager_phone}
                                objState={objState}
                            />
                        :
                            null
                    }
                    <h6 className="text-secondary">Invoice Details</h6>
                    <div className="col-md-6 mb-3">
                        <TextField value={invoice_num} required type="text" label='Invoice No.' onChange={e => setInvoiceNum(e.target.value)} className="form-control" />
                    </div>
                    {
                            invoice_type === 'Recievable'
                        ?
                            <div className="col-md-6 mb-3">
                                <TextField value={sales_order_no} required type="text" label='Sales Order No.' onChange={e => setSalesOrderNo(e.target.value)} className="form-control" />
                            </div>
                        :
                            <div className="col-md-6 mb-3">
                                <TextField value={purchase_order_no} required type="text" label='Purchase Order No.' onChange={e => setPurchaseOrderNo(e.target.value)} className="form-control" />
                            </div>
                    }
                    <div className="col-md-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                className='form-control'
                                label="Date Issued"
                                value={date_issued}
                                onChange={(newValue) => {
                                setDateIssued(newValue);
                                }}
                                renderInput={(params) => <TextField required className='form-control' {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-md-6 mb-3">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Due Date"
                                value={due_date}
                                onChange={(newValue) => {
                                setDueDate(newValue);
                                }}
                                renderInput={(params) => <TextField required className='form-control' {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required 
                            type="number" 
                            prefix='K'
                            value={exvat}
                            label='ExVAT Amount' 
                            onChange={e => setExvat(e.target.value)} 
                            className="form-control"
                            inputProps={{
                            maxLength: 13,
                            step: "1"
                        }}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                    <FormControl required fullWidth>
                        <InputLabel id="demo-simple-select-label">VAT Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                className='form-control p-0'
                                id="demo-simple-select"
                                value={vat_type}
                                required
                                onChange={(event) => seVatType(event.target.value)}
                            >
                                <MenuItem value={'Deductable'}>Deductable</MenuItem>
                                <MenuItem value={'Non Deductable'}>Non Deductable</MenuItem>
                                <MenuItem value={'Exempt'}>Exempt</MenuItem>
                                <MenuItem value={'Unregistered'}>Unregistered</MenuItem>
                            </Select>
        
                    </FormControl>
                    </div>
                    <div className="col-md-6 my-auto">
                        {
                                vat_type === 'Deductable'
                            ?
                                <>
                                    <CurrencyFormat decimalScale={2} prefix='K' thousandSeparator value={(parseFloat(exvat) * 0.16)} displayType={'text'} renderText={value => <div><span className="fw-bold my-3">VAT: </span> {value}</div>}/>
                                    <CurrencyFormat decimalScale={2} prefix='K' thousandSeparator value={(parseFloat(exvat) * 0.16) + parseFloat(exvat)} displayType={'text'} renderText={value => <div><span className="fw-bold my-3">Total: </span> {value}</div>}/>
                                </>
                            :
                                <>
                                    <CurrencyFormat decimalScale={2} prefix='K' thousandSeparator value={0} displayType={'text'} renderText={value => <div><span className="fw-bold my-3">VAT: </span> {value}</div>}/>
                                    <CurrencyFormat decimalScale={2} prefix='K' thousandSeparator value={(parseFloat(exvat))} displayType={'text'} renderText={value => <div><span className="fw-bold my-3">Total: </span> {value}</div>}/>
                                </>
                        }
                    </div>
                    <div className="col-md-12 mt-3 d-flex justify-content-end">
                        {
                                btnLoading
                            ?
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="visually-hidden">Loading...</span>
                                </button>
                            :
                                <button type='submit' className="btn btn-primary">Add</button>
                        }
                        <button onClick={handleReset} className="ms-2 btn btn-warning">Reset</button>
                        <button onClick={handleClose} className="ms-2 btn btn-danger">Close</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Invoice;