import React, { useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import { notify } from '../helpers/functions';
import { ToastContainer } from 'react-toastify';

import logo from '../assets/MEITLogo.png';

const Login = () => {

    const {setIsLoggedIn, setUser, setView} = useLogin()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true)

        debtsAPI.post('/user/login', {
            email, password
        }).then(res => {
            setLoading(false)
            if (res.data.success) {
                notify({message:res.data.message, success:true, position:'BOTTOM_CENTER'})
                setTimeout(() => {
                    sessionStorage.setItem('loggedIn', true);
                    sessionStorage.setItem('user', JSON.stringify(res.data.user));
                    sessionStorage.setItem('view', res.data.user.role === 'General Recievable' ? 'Recievable' : 'Payable')
                    setView(res.data.user.role === 'General Recievable' ? 'Recievable' : 'Payable')
                    setUser(res.data.user);
                    setIsLoggedIn(true)
                }, 2000)
            } else {
                notify({message:res.data.message, error:true, position:'BOTTOM_CENTER'})
            }
        }).catch((err) => {
            notify({message:err.message, error:true})
            setLoading(false)
        })
    }
    return (
        <div>
            <div className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <span className="navbar-brand ms-4">
                    <img src={logo} alt='MEIT Logo' width={80}/>
                </span>
            </div>
            <div className='mt-0 d-flex justify-content-center align-items-center flex-column pb-4' style={{height:'90vh'}}>
                <h1>Login</h1>
                <form onSubmit={handleLogin} style={{width:'20rem'}}>
                    <div className="col-md-12 mb-3">
                        <label htmlFor="Email" className="form-label">Email</label>
                        <input required type="text" onChange={e=>setEmail(e.target.value)} placeholder='Enter your email' className="form-control" />
                    </div>
                    <div className="col-md-12 mb-3">
                        <label htmlFor="Password" className="form-label">Password</label>
                        <input required type="password" onChange={e=>setPassword(e.target.value)} placeholder='Enter your password' className="form-control" />
                    </div>
                    <div className="col-md-12">
                        {
                                loading
                            ?
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="visually-hidden">Loading...</span>
                                </button>
                            :
                                <button type='submit' className="btn btn-primary">Login</button>
                        }
                    </div>
                </form>
                <ToastContainer />
            </div>
            <div className="navbar d-flex align-items-center py-3 justify-content-between fs-6 px-3 start-0 navbar-expand-lg navbar-light bg-light position-fixed w-100 bottom-0">
                <p style={{fontSize: '20px'}} className="my-auto">Business Management System</p>
                <p style={{fontSize: '14px'}} className="my-auto">Developed By MEIT TECHNOLOGIES - Version 1.0.0</p>
            </div>
        </div>
    );
};

export default Login;