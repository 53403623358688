import { TextField } from '@mui/material';
import React, { useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import { useApp } from '../context/AppProvider';
import { notify } from '../helpers/functions';

const InvoiceApproval = ({invoice, amount, balance}) => {

    const { fetch, setFetch } = useApp();
    const { user } = useLogin()

    const [approved_amount, setApprovedAmount] = useState(amount- balance);
    const [btnLoading, setBtnLoading] = useState(null);

    const handleApproval = (e) => {
        e.preventDefault();

        setBtnLoading(true);
        debtsAPI.put(`/invoice/approve/${invoice.id}/${approved_amount}/${user.email}/${user.name}`)
        .then(res => {
            setBtnLoading(false)
            if (res.data.success) {
                notify({message:res.data.message, success:true})
                setFetch(!fetch);
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false)
            console.log(err)
            notify({message:err.message, error:true})
        })
    }

    return (
        <form onSubmit={handleApproval}>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <TextField
                        required 
                        type="number" 
                        prefix='K'
                        value={approved_amount}
                        label='Amount Approved' 
                        onChange={e => setApprovedAmount(e.target.value)} 
                        className="form-control"
                            inputProps={{
                            maxLength: 13,
                            step: "1"
                        }}
                    />
                </div>
                <div className="col-md-12">
                    {
                            btnLoading
                        ?
                            <button className="btn btn-warning" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="visually-hidden">Loading...</span>
                            </button>
                        :
                            <button type='submit' className="btn btn-warning">Approve</button>
                    }
                </div>
            </div>
        </form>
    );
};

export default InvoiceApproval;