import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { loadAnimation } from '../helpers/functions';
  
  const columns = [
    { field: 'performed_by', headerName: 'Comment By', width: 250, editable: false },
    { field: 'comment', headerName: 'Comment', width: 350, editable: false },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 250,
      renderCell: (params) => {
        return (
          <>
              {new Date(params.row.createdAt).toDateString()}
          </>
        );
      }
    }
  ];

const CommentDisplayTable = ({rows}) => {
    
    return (
        <div>
            <div style={{ height: 300, minWidth: '500px' }}>
                {
                  <DataGrid
                    rows={rows}
                    loading={rows ? false : true}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[20]}
                  />
                  || loadAnimation()
                }
            </div>
        </div>
    );
};

export default CommentDisplayTable;