import React, {useContext, createContext, useState} from 'react';
import { useEffect } from 'react';

const LoginContext = createContext()

const LoginProvider = ({children}) => {

    const [user, setUser] = useState(null)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [view, setView] = useState('')

    useEffect(() => {
        sessionStorage.getItem('loggedIn') ? setIsLoggedIn(true) : setIsLoggedIn(false)
        if (sessionStorage.getItem('loggedIn')) {
            setUser(JSON.parse(sessionStorage.getItem('user')))
            setView(sessionStorage.getItem('view'))
        }
    }, [isLoggedIn])

    return (
        <LoginContext.Provider value={{user, setUser, isLoggedIn, setIsLoggedIn, view, setView}}>
            {children}
        </LoginContext.Provider>
    );
};

export const useLogin = () => useContext(LoginContext);

export default LoginProvider;