import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { useApp } from '../context/AppProvider';
import { notify } from '../helpers/functions';

const TaskUpdate = ({row}) => {

    const [task_status, setTaskStatus] = useState('')
    const {setFetch, fetch} = useApp()
    const [btnLoading, setBtnLoading] = useState(null);

    const handleUpdate = () => {

        if (!task_status) {
            notify({message: 'Task status is required', error:true})
            return
        }

        setBtnLoading(true);
        debtsAPI.put(`/tasks/${row.id}/${task_status}`)
        .then(res => {
            setBtnLoading(false);
            if (res.data.success) {
                notify({message:res.data.message, success:true});
                setFetch(!fetch);
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false);
            console.log(err)
        })
    }

    return (
        <form>
            <div className='row'>
                <div className="col-md-12 mb-3 pt-2">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            className='form-controll'
                            id="demo-simple-select"
                            value={task_status}
                            label="Update Task"
                            onChange={e => setTaskStatus(e.target.value)}
                        >
                            {
                                    row.task_status === 'In Progress'
                                ?
                                    null
                                :
                                    <MenuItem value={'In Progress'}>In Progress</MenuItem>

                            }
                            <MenuItem value={'Await Approval'}>Completed</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-12">
                    {
                            btnLoading
                        ?
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="visually-hidden">Loading...</span>
                            </button>
                        :
                            <button type='button' onClick={handleUpdate} className="btn btn-warning">Update</button>
                    }
                </div>
            </div>
        </form>
    );
};

export default TaskUpdate;