import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import { useApp } from '../context/AppProvider';
import { APP_ROLES } from '../helpers/constants';
import { notify } from '../helpers/functions';

const UserRegistration = () => {

    const { user } = useLogin();
    const { fetch, setFetch } = useApp()

    // Database Information
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");

    // Loadind States
    const [btnLoading, setBtnLoading] = useState(null);

    // Handling Submission
    const handleSubmit = (e) => {
        
        e.preventDefault();

        const user_data = {
            first_name,
            last_name,
            password,
            role,
            email,
        }

        setBtnLoading(true);
        debtsAPI.post('/user', user_data)
        .then(res => {
            setBtnLoading(false);
            if (res.data.success) {
                
                setFirstName('')
                setLastName('')
                setRole('')
                setPassword('')
                setEmail('')
                
                notify({message:res.data.message, success:true});

                setFetch(!fetch)
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false);
            console.log(err)
        })
        
    }
    
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={first_name}
                            label='First Name' 
                            onChange={e => setFirstName(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={last_name}
                            label='Last Name' 
                            onChange={e => setLastName(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={email}
                            label='Email' 
                            onChange={e => setEmail(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            type={'password'}
                            value={password}
                            label='Password' 
                            onChange={e => setPassword(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12 mb-3">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                {
                                    user.role === APP_ROLES.manager
                                    ?
                                        <>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={role}
                                            label="Age"
                                            onChange={(event) => setRole(event.target.value)}
                                            >
                                                <MenuItem value={'General Payable'}>General - Payable</MenuItem>
                                                <MenuItem value={'General Recievable'}>General - Recievable</MenuItem>
                                            </Select>
                                        </>
                                    :
                                        <>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={role}
                                            label="Age"
                                            onChange={(event) => setRole(event.target.value)}
                                            >
                                                <MenuItem value={'Admin'}>Admin</MenuItem>
                                                <MenuItem value={'Manager'}>Manager</MenuItem>
                                                <MenuItem value={'General Payable'}>General - Payable</MenuItem>
                                                <MenuItem value={'General Recievable'}>General - Recievable</MenuItem>
                                                <MenuItem value={'General'}>General</MenuItem>
                                            </Select>
                                        </>
                                }
                        </FormControl>
                    </div>
                    <div className="col-md-12">
                        {
                                btnLoading
                            ?
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="visually-hidden">Loading...</span>
                                </button>
                            :
                                <button type='submit' className="btn btn-primary">Add</button>
                        }
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UserRegistration;