import { Autocomplete, Box, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import { useApp } from '../context/AppProvider';
import { notify } from '../helpers/functions';
import { COUNTRIES } from '../helpers/constants';

const ClientRegistration = () => {

    const { user } = useLogin();
    const { fetch, setFetch } = useApp()

    // Database Information
    const [client_name, setClientName] = useState("");
    const [physical_addr, setPhysicalAddress] = useState("");
    const [postal_code, setPostalCode] = useState("");
    const [phone_num, setPhoneNum] = useState("");
    const [company_email, setCompanyEmail] = useState("");
    const [company_reg_num, setCompanyRegNum] = useState("");
    const [tpin_num, setTpin] = useState("");
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [contact_name, setContactName] = useState("");
    const [contact_phone_num, setContactPhoneNum] = useState("");
    const [contact_email, setContactEmail] = useState("");
    const [payment_credit_terms, setPayCredTerms] = useState("");
    const [bank_name, setBankName] = useState("");
    const [bank_branch, setBankBranch] = useState("");
    const [acc_name, setAccName] = useState("");
    const [acc_num, setAccNum] = useState("");
    const [iban_num, setIbanNum] = useState("");
    const [swift_code, setSwiftCode] = useState("");
    const [currency, setCurrency] = useState("");
    const [sort_code, setSortCode] = useState("");

    const [form_type, setFormType] = useState('Supplier');

    // Loadind States
    const [btnLoading, setBtnLoading] = useState(null);

    // Handling Submission
    const handleSubmit = (e) => {
        
        e.preventDefault();

        const supplier_data = {
            client_name,
            postal_code,
            land_line: "",
            phone_num,
            company_email,
            tpin_num,
            company_reg_num,
            city,
            county,
            country,
            province,
            contact_name,
            contact_email,
            contact_phone_num,
            payment_credit_terms,
            physical_addr,
            bank_name,
            bank_branch,
            acc_name,
            acc_num,
            iban_num: parseInt(iban_num),
            swift_code,
            sort_code,
            currency,
            user_type: form_type
        }

        setBtnLoading(true);
        debtsAPI.post(`/client/${user.name}/${user.email}`, supplier_data)
        .then(res => {
            setBtnLoading(false);
            if (res.data.success) {
                
                notify({message:res.data.message, success:true});
                setFetch(!fetch)
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false);
            console.log(err)
        })
        
    }

    const handleChange = (e) => {
        setFormType(e.target.value)
    }
    
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <FormLabel id="demo-controlled-radio-buttons-group">Client Type</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={form_type}
                            row
                            onChange={handleChange}
                        >
                            <FormControlLabel value="Supplier" control={<Radio />} label="Supplier" />
                            <FormControlLabel value="Customer" control={<Radio />} label="Customer" />
                            <FormControlLabel value="Customer / Supplier" control={<Radio />} label="Customer / Supplier" />
                        </RadioGroup>
                    </div>
                    <h6>Contact Details</h6>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={client_name}
                            label='Name of Client' 
                            onChange={e => setClientName(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={postal_code}
                            label='Postal Code' 
                            onChange={e => setPostalCode(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={physical_addr}
                            label='Physical Address' 
                            onChange={e => setPhysicalAddress(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={phone_num}
                            label='Phone Number' 
                            onChange={e => setPhoneNum(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                        required
                            value={company_email}
                            label='Company Email' 
                            onChange={e => setCompanyEmail(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <h6>Tax Payer & Company Informtion</h6>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={tpin_num}
                            label='TPIN Number' 
                            onChange={e => setTpin(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={company_reg_num}
                            label='Company Registration Number' 
                            onChange={e => setCompanyRegNum(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <h6>Location</h6>
                    <div className="col-md-6 mb-3">
                        <TextField
                            required
                            value={city}
                            label='City' 
                            onChange={e => setCity(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            value={county}
                            label='County' 
                            onChange={e => setCounty(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <Autocomplete
                            id="country-select-demo"
                            className='w-100'
                            options={COUNTRIES}
                            autoHighlight
                            onInputChange={(e, value) => setCountry(value)}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                {option.label} ({option.code}) +{option.phone}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                required
                                {...params}
                                label="Choose a country"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                />
                            )}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            value={province}
                            label='Province' 
                            onChange={e => setProvince(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <h6>Contact Person</h6>
                    <div className="col-md-6 mb-3">
                        <TextField
                            required
                            value={contact_name}
                            label='Contact Name' 
                            onChange={e => setContactName(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required
                            value={contact_phone_num}
                            label='Contact Phone Number' 
                            onChange={e => setContactPhoneNum(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                            required
                            value={contact_email}
                            label='Contact Email' 
                            onChange={e => setContactEmail(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl required fullWidth>
                            <InputLabel id="demo-simple-select-label">Payment Credit Terms</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                className='form-controll'
                                id="demo-simple-select"
                                value={payment_credit_terms}
                                label="Update Task"
                                onChange={e => setPayCredTerms(e.target.value)}
                            >
                                <MenuItem value={'Cash On Delivery'}>Cash On Delivery</MenuItem>
                                <MenuItem value={'7 Days'}>7 Days</MenuItem>
                                <MenuItem value={'14 Days'}>14 Days</MenuItem>
                                <MenuItem value={'21 Days'}>21 Days</MenuItem>
                                <MenuItem value={'30 Days'}>30 Days</MenuItem>
                                <MenuItem value={'45 Days'}>45 Days</MenuItem>
                                <MenuItem value={'60 Days'}>60 Days</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <h6>Bank Details</h6>
                    <div className="col-md-6 mb-3">
                        <TextField
                            required={form_type === 'Customer' ? false : true}
                            disabled={form_type === 'Customer' ? true : false}
                            value={bank_name}
                            label='Bank Name' 
                            onChange={e => setBankName(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField 
                            required={form_type === 'Customer' ? false : true}
                            disabled={form_type === 'Customer' ? true : false}
                            value={bank_branch}
                            label='Bank Branch' 
                            onChange={e => setBankBranch(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                            required={form_type === 'Customer' ? false : true}
                            disabled={form_type === 'Customer' ? true : false}
                            value={acc_name}
                            label='Account Name' 
                            onChange={e => setAccName(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                            required={form_type === 'Customer' ? false : true}
                            disabled={form_type === 'Customer' ? true : false}
                            value={acc_num}
                            label='Account Number' 
                            onChange={e => setAccNum(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                            disabled={form_type === 'Customer' ? true : false}
                            value={iban_num}
                            label='IBAN No.' 
                            onChange={e => setIbanNum(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                            required={form_type === 'Customer' ? false : true}
                            disabled={form_type === 'Customer' ? true : false}
                            value={swift_code}
                            label='Swift Code' 
                            onChange={e => setSwiftCode(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <TextField
                            disabled={form_type === 'Customer' ? true : false}
                            value={sort_code}
                            label='Sort Code' 
                            onChange={e => setSortCode(e.target.value)} 
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormControl required fullWidth>
                            <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                className='form-controll'
                                id="demo-simple-select"
                                value={currency}
                                label="Update Task"
                                onChange={e => setCurrency(e.target.value)}
                            >
                            <MenuItem value={'Rand'}>Rand</MenuItem>
                            <MenuItem value={'Euro'}>Euro</MenuItem>
                            <MenuItem value={'USD'}>USD</MenuItem>
                            <MenuItem value={'Kwacha'}>Kwacha</MenuItem>
                            <MenuItem value={'GBP'}>GBP</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12 d-flex justify-content-end">
                        {
                                btnLoading
                            ?
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="visually-hidden">Loading...</span>
                                </button>
                            :
                                <button type='submit' className="btn btn-primary">Add</button>
                        }
                        <button className="btn btn-danger ms-2">Close</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ClientRegistration;