import { Box, Dialog, DialogContent, DialogTitle, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CommentDisplayTable from '../components/CommentDisplayTable';
import InvoicePreview from '../PopupContents/InvoicePreview';
import InvoiceComment from '../forms/InvoiceComment';

const InvoiceDetailsModal = ({children, title, row, fullScreen=false}) => {
        const [open, setOpen] = useState(false);
        const [value, setValue] = React.useState(0);
        const comments = row.InvoiceLogs.filter(item => item.comment !== null)

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        function TabPanel({value, index, children}) {
          
            return (
              <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
              >
                {value === index && (
                  <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                  </Box>
                )}
              </div>
            );
          }

        return (
            <div>
                <span onClick={handleClickOpen}>
                    {children}
                </span>
                <Dialog
                    open={open}
                    scroll={'paper'}
                    fullScreen={fullScreen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <span className='position-relative bg-dark w-100'>
                        <span onClick={handleClose} className="position-absolute end-0 m-3" style={{cursor:'pointer'}}>
                            <CloseIcon />
                        </span>
                    </span>
                    <DialogTitle id="alert-dialog-title">
                    {title}
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Invoice Details"  />
                                    <Tab label="Add Comment"  />
                                    <Tab label="Comments"  />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <InvoicePreview handleClose={handleClose} row={row}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <InvoiceComment handleClose={handleClose} data={row}/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <CommentDisplayTable rows={comments}/>
                            </TabPanel>
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>

    );
};

export default InvoiceDetailsModal;