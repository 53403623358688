import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CustomerTable from '../tables/CustomerTable';
import ReportTable from '../tables/ReportTable';
import SupplierTable from '../tables/SupplierTable';
import TasksTable from '../tables/TasksTable';
import TasksYouAssignedTable from '../tables/TaskYouAssignedTable';
import UserTable from '../tables/UserTable';
import { useLogin } from '../context/LoginProvider';
import { APP_ROLES } from '../helpers/constants';
import Dashboard from '../pages/Dashboard';
import SearchResults from '../pages/SearchResults';
import InvoiceTable from '../tables/InvoiceTable';
import Home from '../pages/Home';

const MainNavigation = () => {

    const {user} = useLogin();

    return (
        <Routes>
            <Route path='/' element={<Dashboard/>}>
                {
                        user.role === APP_ROLES.general
                    ?
                        <Route index element={<TasksTable/>} />
                    :
                        <Route index element={<Home/>} />
                }   
                
                <Route path='tasks' element={<TasksTable/>}/>
                <Route path='othersTasks' element={<TasksYouAssignedTable/>}/>
                
                {
                        user.role === APP_ROLES.admin || user.role === APP_ROLES.manager || user.role === APP_ROLES.generalPayable || user.role === APP_ROLES.generalRecievable
                    ?
                        <>
                            <Route path='invoices/:filter' element={<InvoiceTable/>}/>
                            <Route path='users' element={<UserTable/>}/>
                            <Route path='search/:keyword' element={<SearchResults/>} />
                        </>
                    :
                        null
                }
                {
                        user.role === APP_ROLES.admin || user.role === APP_ROLES.manager
                    ?
                        <>
                            <Route path='reports/:filter' element={<ReportTable/>} />
                            <Route path='suppliers/:filter' element={<SupplierTable/>}/>
                            <Route path='customers/:filter' element={<CustomerTable/>}/>
                        </>
                    :
                        null
                }
                <Route path='*' element={<Home/>}/>
            </Route>
        </Routes>
    );
};

export default MainNavigation;