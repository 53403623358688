import React from 'react';
import { NavLink } from 'react-router-dom';
import { loadAnimation } from '../helpers/functions';
import GeneralModal from '../modals/GeneralModal';

const TaskLink = ({myPendingTasks, othersPendingTasks}) => {
    return (
        <>
            <li className="nav-item dropdown">
                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    TASKS <span className="badge bg-danger me-1">
                                {
                                    myPendingTasks + othersPendingTasks > 9 ? "9+" : (myPendingTasks + othersPendingTasks ? myPendingTasks + othersPendingTasks : '0')
                                }
                            </span>
                </span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <span>
                        <GeneralModal title={'Assign Task'} modalFor='TaskCreation'>
                            <span className="dropdown-item" role="button">Assign Task</span>
                        </GeneralModal>
                    </span>
                    <span>
                        <NavLink to='/othersTasks' className="dropdown-item" role="button">Others Tasks <span className="badge bg-warning">
                                {
                                    (othersPendingTasks > 9 ? "9+" : (othersPendingTasks ? othersPendingTasks : '0'))
                                    || loadAnimation()
                                }
                            </span></NavLink>
                    </span>
                    <span>
                        <NavLink to='/tasks' className="dropdown-item" role="button">My Tasks <span className="badge bg-danger">
                                {
                                    (myPendingTasks > 9 ? "9+" : (myPendingTasks ? myPendingTasks : '0'))
                                    || loadAnimation()
                                }
                            </span></NavLink>
                    </span>
                </ul>
            </li>
        </>
    );
};

export default TaskLink;