import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLogin } from '../context/LoginProvider';
import { APP_ROLES } from '../helpers/constants';
import { loadAnimation } from '../helpers/functions';
import UserModal from '../modals/UserModal';

const ManageLink = ({totalUnApprovedSuppliers, totalUnApprovedCustomers}) => {

    const { user } = useLogin()

    return (
        <>
            <li className="nav-item dropdown">
                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    MANAGE {user.role === APP_ROLES.admin || user.role === APP_ROLES.manager ? <span className="badge bg-danger">
                                {
                                    (totalUnApprovedSuppliers + totalUnApprovedCustomers > 9 ? "9+" : (totalUnApprovedSuppliers + totalUnApprovedCustomers) ? totalUnApprovedSuppliers + totalUnApprovedCustomers : '0')
                                    || loadAnimation()
                                }
                            </span> : null}
                </span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <span>
                        <UserModal title='Add Supplier / Customer' formType='Supplier'>
                            <span className="dropdown-item" role="button">Supplier / Customer</span>
                        </UserModal>
                    </span>
                    {
                            user.role === APP_ROLES.admin || user.role === APP_ROLES.manager
                        ?
                            <>
                                <span>
                                    <NavLink to='/suppliers/suppliers' className="dropdown-item" role="button">View Suppliers <span className="badge bg-danger">
                                            {
                                                (totalUnApprovedSuppliers > 9 ? "9+" : (totalUnApprovedSuppliers ? totalUnApprovedSuppliers : '0')) 
                                                || loadAnimation()
                                            }
                                        </span></NavLink>
                                </span>
                                <span>
                                    <NavLink to='/customers/customers' className="dropdown-item" role="button">View Customers <span className="badge bg-danger">
                                            {
                                                (totalUnApprovedCustomers > 9 ? "9+" : (totalUnApprovedCustomers ? totalUnApprovedCustomers : '0'))
                                                || loadAnimation() 
                                            }
                                        </span></NavLink>
                                </span>
                            </>
                        :
                            null
                    }
                </ul>
            </li>
        </>
    );
};

export default ManageLink;