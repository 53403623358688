import React from 'react';
import { NavLink } from 'react-router-dom';
import UserModal from '../modals/UserModal';

const UserLink = () => {
    return (
        <>
            <li className="nav-item dropdown">
                <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    USERS
                </span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <span>
                        <UserModal title='Add User'>
                            <span className="dropdown-item" role="button">Add User</span>
                        </UserModal>
                    </span>
                    <span>
                        <NavLink to='/users' className="dropdown-item" role="button">View Users</NavLink>
                    </span>
                </ul>
            </li>
        </>
    );
};

export default UserLink;