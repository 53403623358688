import React from 'react';
import InvoiceActionCard from '../components/InvoiceActionCard';
import { useApp } from '../context/AppProvider';
import { useLogin } from '../context/LoginProvider';
import { APP_ROLES } from '../helpers/constants';
import InvoiceModal from '../modals/InvoiceModal';

const InvoiceLink = () => {

    const { user } = useLogin()
    const { fetch, setFetch } = useApp()

    return (
        <>
            {
                user.role === APP_ROLES.admin || user.role === APP_ROLES.manager
                ?
                    <>
                        <div className="col-md-3">
                            <InvoiceActionCard title={'Payables'} path={'/invoices/allPayableInvoices'}>
                                <InvoiceModal setFetch={setFetch} fetch={fetch} title={"Payable"}>
                                    <button className="btn btn-primary">Add Payable</button>
                                </InvoiceModal>
                            </InvoiceActionCard>
                        </div>
                        <div className="col-md-3">
                            <InvoiceActionCard title={'Recievables'} path={'/invoices/allRecievableInvoices'}>
                                <InvoiceModal setFetch={setFetch} fetch={fetch} title={"Recievable"} isPayable={false}>
                                    <button className="btn btn-primary">Add Recievable</button>
                                </InvoiceModal>
                            </InvoiceActionCard>
                        </div>
                    </>
                :
                    <>
                        {
                            user.role === APP_ROLES.generalPayable
                            ?
                                <div className="col-md-3">
                                    <InvoiceActionCard title={'Payables'} path={'/invoices/allPayableInvoices'}>
                                        <InvoiceModal setFetch={setFetch} fetch={fetch} title={"Payable"}>
                                            <button className="btn btn-primary">Add Payable</button>
                                        </InvoiceModal>
                                    </InvoiceActionCard>
                                </div>
                            :
                                <div className="col-md-3">
                                    <InvoiceActionCard title={'Recievables'} path={'/invoices/allRecievableInvoices'}>
                                        <InvoiceModal setFetch={setFetch} fetch={fetch} title={"Recievable"} isPayable={false}>
                                            <button className="btn btn-primary">Add Recievable</button>
                                        </InvoiceModal>
                                    </InvoiceActionCard>
                                </div>
                        }
                    </>
            }
        </>
    );
};

export default InvoiceLink;