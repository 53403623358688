import React, { useEffect } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useApp } from '../context/AppProvider';
import debtsAPI from '../api/debtsAPI';
import { useState } from 'react';
import { useLogin } from '../context/LoginProvider';
import { notify } from '../helpers/functions';
import { APP_ROLES } from '../helpers/constants';

import logo from '../assets/MEITLogo.png';
import './styles.css';
import TaskLink from '../links/TaskLink';
import UserLink from '../links/UserLink';
import InvoiceLink from '../links/InvoiceLink';
import PayableLink from '../links/PayableLink';
import RecievableLink from '../links/RecievableLink';
import PaymentLink from '../links/PaymentLink';
import ReportsLink from '../links/ReportsLink';
import ManageLink from '../links/ManageLink';
import ProfileLink from '../links/ProfileLink';
import DashBoardLink from '../links/DashboardLink';

const Dashboard = () => {
    
    const {setUsers, setAllInvoices, allInvoices, fetch, suppliers, setSuppliers, 
           customers, setCustomers, myPendingTasks, setMyPendingTasks, othersPendingTasks, setOthersPendingTasks} = useApp()
    const {setIsLoggedIn, isLoggedIn, user, view} = useLogin()
    
    const [dataLoaded, setDataLoaded] = useState(false)

    const [keyword, setKeyword] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        

        setDataLoaded(false)
        
        // Retrieve all invoices (paid, unpaid, pending and partially paid)
        debtsAPI.get('/invoice')
        .then(res => {
            setAllInvoices(res.data);

            // Retrieve all users of the system except currently logged in
            return debtsAPI.get(`/user/${user.id}`);
        }).then(res => {
            setUsers(res.data.users)

            // Retrieve all clients - customers and suppliers
            return debtsAPI.get('/client');
        }).then(res => {
            setSuppliers(res.data.suppliers)
            setCustomers(res.data.customers)

            // Retrieve all tasks Assigned to Logged In User
            return debtsAPI.get(`/tasks/assigneeTasks/${user.email}`)
        }).then(res => {
            console.log(res.data)
            setMyPendingTasks(res.data)
            // Retrieve all tasks You Assigned to Other Users
            return debtsAPI.get(`/tasks/assignerTasks/${user.email}`)
        }).then(res => {
            setOthersPendingTasks(res.data)
            setDataLoaded(true)
        }).catch(err => {
            notify({message: err.message, error:true})
        })
    }, [setAllInvoices, fetch, setUsers, setIsLoggedIn, isLoggedIn, setSuppliers, user, setCustomers, setOthersPendingTasks, setMyPendingTasks])
    
    const handleLogout = () => {
        sessionStorage.clear();
        notify({message:'Logged out successfully.', success:true})
        setTimeout(() => {
            setIsLoggedIn(!isLoggedIn)
            navigate('/')
        }, 2000)
    }

    const handleSearch = (e) => {
        if (keyword && e.key === "Enter") {
            navigate(`/search/${keyword}`)
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <NavLink to='/' className="navbar-brand me-4">
                        <img src={logo} alt='MEIT Logo' width={80}/>
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mt-2 mb-2 mb-lg-0">
                            {
                                    user.role === APP_ROLES.general
                                ?
                                    <TaskLink myPendingTasks={myPendingTasks.Tasks && myPendingTasks.Tasks['allPendingTasks'].length + myPendingTasks.Tasks['allInProgressTasks'].length} 
                                              othersPendingTasks={othersPendingTasks.Tasks && othersPendingTasks.Tasks['allPendingTasks'].length + othersPendingTasks.Tasks['allInProgressTasks'].length} />
                                :
                                    <>
                                        <DashBoardLink />
                                        <PaymentLink totalUnpaidPayables={allInvoices.totalUnpaidPayables && allInvoices.totalUnpaidPayables} totalUnpaidRecievables={allInvoices.totalUnpaidRecievables && allInvoices.totalUnpaidRecievables} />
                                        <>
                                            <ManageLink totalUnApprovedCustomers={parseFloat(customers.totalUnApproved)} totalUnApprovedSuppliers={parseFloat(suppliers.totalUnApproved)} />
                                            {
                                                user.role === APP_ROLES.admin || user.role === APP_ROLES.manager
                                                ?
                                                    <>
                                                        <ReportsLink />
                                                        <UserLink/>
                                                    </>
                                                :
                                                    null
                                            }
                                            <TaskLink myPendingTasks={myPendingTasks.Tasks && myPendingTasks.Tasks['allPendingTasks'].length + myPendingTasks.Tasks['allInProgressTasks'].length} 
                                              othersPendingTasks={othersPendingTasks.Tasks && othersPendingTasks.Tasks['allPendingTasks'].length + othersPendingTasks.Tasks['allInProgressTasks'].length} />
                                        </>
                                    </>
                            }
                        </ul>
                        {
                                user.role === APP_ROLES.general
                            ?
                                null
                            :
                                <div className="d-flex">
                                    <input disabled className="form-control" onChange={(e) => setKeyword(e.target.value)} onKeyDown={handleSearch} type="search" placeholder="Search Item..." aria-label="Search"/>
                                </div>
                        }
                        <ProfileLink handleLogout={handleLogout} />
                    </div>
                </div>
            </nav>
            <div className="container mt-3" style={{paddingBottom:'70px'}}>
                <ToastContainer />
                {
                    //     dataLoaded
                    // ?
                        <Outlet />
                    // :
                    //     <Skeleton width={'100%'} height={400}/>
                }
            </div>
            <div className="navbar d-flex align-items-center py-3 justify-content-between fs-6 px-3 start-0 navbar-expand-lg navbar-light bg-light position-fixed w-100 bottom-0">
                <p style={{fontSize: '20px'}} className="my-auto">Business Management System</p>
                <p style={{fontSize: '14px'}} className="my-auto">Developed By MEIT TECHNOLOGIES - Version 1.0.0</p>
            </div>       
        </>
    );
};

export default Dashboard;