import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { NavLink } from 'react-router-dom';
import { loadAnimation } from '../helpers/functions';

const StatCard = ({title, value, colorName, path, pathName}) => {

    const setColor = (name) => {
        if (name === 'red') {
            return ('bg-danger');
        } else if (name === 'green') {
            return ('bg-success')
        } else {
            return ('bg-warning');
        }
    }

    return (
        <div className="card">
            <h5 className={`card-header text-light ${setColor(colorName)}`}>{title}</h5>
            <div className="card-body">
                <h5 className="card-title">Total</h5>
                <CurrencyFormat value={value ? value : 0} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <p className="card-text">{value || loadAnimation()}</p>} />
                <NavLink to={`/invoices/${path}`} className="btn btn-primary">{pathName}</NavLink>
            </div>
        </div>
    );
};

export default StatCard;