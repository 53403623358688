import * as React from 'react';
import { useApp } from '../context/AppProvider';
import { NavLink } from 'react-router-dom';
import UserDisplayTable from './UserDisplayTable';

const UserTable = () => {
  
  const {fetch, setFetch, users} = useApp()
  

  return (
    <div style={{ height: 500, width: '100%' }}>
      <div className="row mb-3">
        <div className="col-md-12 d-flex mb-3">
          <NavLink to={'/'} className="btn btn-danger me-3">Back</NavLink>
          <h4>Users</h4>
        </div>
        {/* <div className="col-md-5 d-flex">
            <input value={keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
            <button className="btn btn-outline-success">Search</button>
        </div> */}
        {/* <div className="col-md-3 my-auto">
            <div className="dropdown">
              <FilterAltIcon  className="dropdown-toggle fs-4" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"/> <span>Filter</span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <span className="dropdown-item" onClick={() => setDataToShow('allInvoices')} role={'button'}>All Invoices</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('paidInvoices')} role={'button'}>Paid Invoices</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('pendingInvoices')} role={'button'}>Unpaid Invoices</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('paidRecievableInvoices')} role={'button'}>Paid Recievables</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('pendingRecievableInvoices')} role={'button'}>Unpaid Recievables</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('partiallyPaidRecievableInvoices')} role={'button'}>Partially Paid Recievables</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('paidPayableInvoices')} role={'button'}>Paid Payables</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('pendingPayableInvoices')} role={'button'}>Unpaid Payables</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('partiallyPaidPayableInvoices')} role={'button'}>Partially Paid Payables</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('allPayableInvoices')} role={'button'}>Payables</span>
                  <span className="dropdown-item" onClick={() => setDataToShow('allRecievableInvoices')} role={'button'}>Recievables</span>
              </ul>
            </div>
        </div> */}
      </div>
        <UserDisplayTable fetch={fetch} setFetch={setFetch} rows={users} />
    </div>
  );
}


export default UserTable;