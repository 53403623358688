import { Autocomplete, Box, Skeleton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { useLogin } from '../context/LoginProvider';
import { useApp } from '../context/AppProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { notify } from '../helpers/functions';

const Task = () => {

    // Database Information
    const [task_description, setTaskDescription] = useState("");
    const [task_end, setTaskEnd] = useState(new Date());
    const [task_recipient, setTaskRecipient] = useState(null);

    const {fetch, setFetch, users} = useApp()
    const { user } = useLogin()

    // Loading States
    const [btnLoading, setBtnLoading] = useState(null);
    const [dataLoading, setDataLoading] = useState(null);

    // Handling Submission
    const handleSubmit = (e) => {
        
        e.preventDefault();

        const commentData = {
            assigner_name: user.name,
            assigner_email: user.email,
            assignee_name: task_recipient.first_name + ' ' + task_recipient.last_name,
            assignee_email: task_recipient.email,
            task_description,
            task_end,
        }

        setBtnLoading(true);

        debtsAPI.post(`/tasks`, commentData)
        .then(res => {
            setBtnLoading(false);
            if (res.data.success) {
                notify({message:res.data.message, success:true});
                setFetch(!fetch);
            } else {
                notify({message:res.data.message, error:true})
            }
        }).catch(err => {
            setBtnLoading(false);
            console.log(err)
        })
        
    }

    // Handle End Time
    const handleEnd = (datetime) => {
        setTaskEnd(datetime)
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                {
                        dataLoading
                    ?
                        <Skeleton width={'100%'} height={100}/>
                    :
                        <div>
                            <div className="row">
                                <div className="col-md-12 pt-2 mb-3">
                                    <Autocomplete
                                        id="country-select-demo"
                                        options={users}
                                        autoHighlight
                                        getOptionLabel={(option) => {
                                            return option.first_name + ' ' + option.last_name;
                                        }}
                                        onChange={(e, user) => setTaskRecipient(user)}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.first_name + ' ' + option.last_name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            label={'Assign Task To *'}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            label="Required By"
                                            value={task_end}
                                            onChange={handleEnd}
                                            renderInput={(params) => <TextField className='form-control' {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <TextField
                                        type={'text'}
                                        value={task_description}
                                        label='Task Description'
                                        onChange={e => setTaskDescription(e.target.value)}
                                        className="form-control"
                                        multiline={true}
                                        maxRows={5}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12">
                                    {
                                            btnLoading
                                        ?
                                            <button className="btn btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="visually-hidden">Loading...</span>
                                            </button>
                                        :
                                            <button type='submit' className="btn btn-warning">Assign</button>
                                    }
                                </div>
                            </div>
                        </div>           
                }
            </form>
        </div>
    );
};

export default Task;