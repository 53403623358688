import React from 'react';
import { NavLink } from 'react-router-dom';

const ReportsLink = () => {
    return (
        <>
            <li className="nav-item">
                <NavLink to={`/reports/allInvoices`} className="nav-link">REPORTS</NavLink>
            </li>
        </>
    );
};

export default ReportsLink;