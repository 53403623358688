import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import UserRegistration from '../forms/UserRegistration';
import EditUser from '../forms/EditUser';
import ClientRegistration from '../forms/ClientRegistration';

const UserModal = ({children, title, id, adminEdit=true, formType='User'}) => {
        const [open, setOpen] = useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        return (
            <div>
                <span onClick={handleClickOpen}>
                    {children}
                </span>
                <Dialog
                    open={open}
                    scroll={'paper'}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <span className='position-relative bg-dark w-100'>
                        <span onClick={handleClose} className="position-absolute end-0 m-3" style={{cursor:'pointer'}}>
                            <CloseIcon />
                        </span>
                    </span>
                    <DialogTitle id="alert-dialog-title">
                    {title}
                    </DialogTitle>
                    <DialogContent>
                        <div className="mt-1">
                            {
                                    formType === 'User'
                                ?
                                    <>
                                        {
                                                id
                                            ?
                                                <EditUser id={id} editProfile={adminEdit}/>
                                            :
                                                <UserRegistration />
                                        }
                                    </>
                                :
                                    <>
                                        <ClientRegistration />
                                    </>
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

    );
};

export default UserModal;