import React from 'react';
import { NavLink } from 'react-router-dom';

const DashBoardLink = () => {
    return (
        <>
            <li className="nav-item">
                <NavLink to={`/`} className="nav-link">DASHBOARD</NavLink>
            </li>
        </>
    );
};

export default DashBoardLink;