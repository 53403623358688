import React, {useState, useContext, createContext} from 'react';

const AppContext = createContext()

const AppProvider = ({children}) => {

    const [allInvoices, setAllInvoices] = useState([])
    const [users, setUsers] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [customers, setCustomers] = useState([])
    const [myPendingTasks, setMyPendingTasks] = useState([])
    const [othersPendingTasks, setOthersPendingTasks] = useState([])
    const [fetch, setFetch] = useState(false)

    return (
        <AppContext.Provider value={
            {allInvoices, setAllInvoices, fetch, setFetch, users, setUsers, suppliers, setSuppliers, customers, setCustomers, myPendingTasks, setMyPendingTasks, othersPendingTasks, setOthersPendingTasks}}>
            {children}
        </AppContext.Provider>
    );
};

export const useApp = () => useContext(AppContext);

export default AppProvider;