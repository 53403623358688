import React, {useEffect, useState} from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import debtsAPI from '../api/debtsAPI';
import { notify } from '../helpers/functions';

import { useLogin } from '../context/LoginProvider';
import { APP_ROLES, INVOICE_STATUS } from '../helpers/constants';
import { defaultColumnsPayable, defaultColumnsRecievable, generalColumns, specialColumns, reportColumns } from '../helpers/DisplayTableConstants';
import { Skeleton } from '@mui/material';


  
  const DisplayTable = ({rows, fetch, setFetch, dataToShow, showReports=false}) => {
    
    const [load, setLoad] = useState(null);
    const {user, view} = useLogin();
    const [paymentRows, setPaymentRows] = useState([])
    
    // Conditionally Filter unpaid rows
    useEffect(() => {
      if (view === "Payable") {
        rows.allPayableInvoices && setPaymentRows(rows.allPayableInvoices.filter(invoice => 
          invoice.status === INVOICE_STATUS.unPaid || invoice.status === INVOICE_STATUS.partiallyPaid))
      } else {
        rows.allRecievableInvoices && setPaymentRows(rows.allRecievableInvoices.filter(invoice => 
          invoice.status === INVOICE_STATUS.unPaid || invoice.status === INVOICE_STATUS.partiallyPaid))
      }
    }, [view, rows])



    // Update handler
    const handleProcess = (id, amount) => {
        setLoad(true);
        debtsAPI.put(`/invoice/${id}/${amount}/${user.email}/${user.name}`)
        .then(res => {
        setLoad(false)
        if (res.data.success) {
            notify({message:res.data.message, success:true})
            setFetch(!fetch);
        } else {
            notify({message:res.data.message, error:true})
        }
        }).catch(err => {
        setLoad(false)
        console.log(err)
        })
    }

    const handlePaymentUpdate = (cell) => {
      console.log(cell)
    }

    return (
        <div>
            {
                    load
                ?
                    <Skeleton width={'100%'} height={400} />
                :
                    <div style={{ height: 400, width: '100%' }}>
                      
                      {/* Payments */}
                      
                      {
                          dataToShow === 'Payments'
                        ?
                          <>
                            {
                                user.role === APP_ROLES.admin || user.role === APP_ROLES.manager
                              ?
                                <DataGrid
                                    rows={paymentRows}
                                    columns={specialColumns}
                                    loading={paymentRows ? false : true}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    onCellEditCommit={handlePaymentUpdate}
                                    components={{Toolbar:GridToolbar}}
                                />
                              :
                                <DataGrid
                                    rows={paymentRows}
                                    loading={paymentRows ? false : true}
                                    columns={generalColumns({
                                      updateHandler: handleProcess 
                                    })}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    components={{Toolbar:GridToolbar}}
                                />
                            }
                          </>
                        :
                          null

                      }
                      
                      {/* Payables & Recievables */}
                      
                      {
                          dataToShow !== 'Payments' && !showReports
                        ?
                          <DataGrid
                              rows={rows[dataToShow] ? rows[dataToShow] : []}
                              loading={rows[dataToShow] ? false : true}
                              columns={view === 'Recievable' ? defaultColumnsRecievable : defaultColumnsPayable}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              components={{Toolbar:GridToolbar}}
                          />
                        :
                          null
                      }

                      {/* Reports */}
                      {
                          showReports
                        ?
                          <DataGrid
                              rows={rows[dataToShow] ? rows[dataToShow] : []}
                              loading={rows[dataToShow] ? false : true}
                              columns={reportColumns}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              components={{Toolbar:GridToolbar}}
                          />
                        :
                          null
                      }
                    </div>
            }
        </div>
    );
};

export default DisplayTable;