import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Chip, Skeleton } from '@mui/material';
import { useState } from 'react';
import debtsAPI from '../api/debtsAPI';
import { notify } from '../helpers/functions';
import UserModal from '../modals/UserModal';

const switchActive = (status) => {
    if (status) {
        return <Chip className='text-success' label={'Active'} />
    } else {
        return <Chip className='text-danger' label={'Not Active'} />
    }
  }
  
  const columns = [
    { field: 'first_name', headerName: 'First Name', width: 200, editable: false },
    { field: 'last_name', headerName: 'Last Name', width: 200, editable: false },
    { field: 'email', headerName: 'Email', width: 200, editable: false },
    { field: 'role', headerName: 'Role', width: 200, editable: false },
    {
      field: 'active',
      headerName: 'Active',
      width: 200,
      renderCell: (params) => {
        return (
          <>
              {
                switchActive(params.row.active)
              }
          </>
        );
      }
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 200,
        renderCell: (params) => {
          return (
            <>
                {
                  <UserModal title={'Update User Details'} id={params.row.id}>
                    <button className="btn btn-warning btn-sm">Update</button>
                  </UserModal>
                }
            </>
          );
        }
      }
  ];

const UserDisplayTable = ({rows, fetch, setFetch}) => {

    const [load, setLoad] = useState(null);

    // Update handler
    const handleProcess = (cell) => {
        setLoad(true);
        debtsAPI.put(`/user/${cell.id}/${cell.value}`)
        .then(res => {
        setLoad(false)
        if (res.data.success) {
            setFetch(!fetch);
            notify({message:res.data.message, success:true})
        } else {
            notify({message:res.data.message, error:true})
        }
        }).catch(err => {
          setLoad(false)
          console.log(err)
          notify({message:err.message, error:true})
        })
    }
    
    return (
        <div>
            {
                    load
                ?
                    <Skeleton width={'100%'} height={500} />
                :
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={rows ? rows : []}
                            loading={rows ? false : true}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            onCellEditCommit={handleProcess}
                        />
                    </div>
            }
        </div>
    );
};

export default UserDisplayTable;