import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useApp } from '../context/AppProvider';

const JobManager = ({setCompanyJobManager, objState, name, email, phone, setClientManagerName, setClientManagerEmail, setClientManagerPhone}) => {

    const { users } = useApp();

    return (
        <>
            <div className="col-md-12 mb-3">
                <h6 className="text-secondary">Company Job Manager Details</h6>
                <Autocomplete
                    key={objState}
                    id="country-select-demo"
                    options={users}
                    className="w-100"
                    autoHighlight
                    getOptionLabel={(option) => {
                        return option.first_name + ' ' + option.last_name;
                    }}
                    onChange={(e, user) => setCompanyJobManager(user)}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.first_name + ' ' + option.last_name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            required
                            className='w-100'
                            {...params}
                            label={'Choose A Manager'}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                        />
                    )}
                />
            </div>
            <h6 className="text-secondary">Customer Job Manager Details</h6>
            <div className="col-md-6 mb-3">
                <TextField value={name} required type="text" label='Name' onChange={e => setClientManagerName(e.target.value)} className="form-control" />
            </div>
            <div className="col-md-6 mb-3">
                <TextField value={email} required type="text" label='Email' onChange={e => setClientManagerEmail(e.target.value)} className="form-control" />
            </div>
            <div className="col-md-6 mb-3">
                <TextField value={phone} required type="text" label='Phone' onChange={e => setClientManagerPhone(e.target.value)} className="form-control" />
            </div>
        </>
    );
};

export default JobManager;