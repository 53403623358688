import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CurrencyFormat from 'react-currency-format';
  
  const columns = [
    {
        field: 'approved_by',
        headerName: 'Approved By',
        width: 200,
        renderCell: (params) => {
          return (
            <>
              {params.row.approved_by ? params.row.approved_by : 'None'}
            </>
          );
        }
    },
    {
        field: 'amount_approved',
        headerName: 'Amount Approved',
        width: 200,
        renderCell: (params) => {
          return (
            <>
              <CurrencyFormat decimalScale={2} value={params.row.amount_approved ? params.row.amount_approved : 0} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
            </>
          );
        }
    },
    {
        field: 'paid_by',
        headerName: 'Paid By',
        width: 200,
        renderCell: (params) => {
          return (
            <>
              {params.row.paid_by ? params.row.paid_by : 'None'}
            </>
          );
        }
    },
    {
        field: 'amount_paid',
        headerName: 'Amount Paid',
        width: 200,
        renderCell: (params) => {
          return (
            <>
              <CurrencyFormat decimalScale={2} value={params.row.amount_paid ? params.row.amount_paid : 0} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <div>{value}</div>} />
            </>
          );
        }
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 200,
      renderCell: (params) => {
        return (
          <>
              {new Date(params.row.createdAt).toLocaleString()}
          </>
        );
      }
    },
    {
        field: 'updatedAt',
        headerName: 'Last Updated',
        width: 200,
        renderCell: (params) => {
          return (
            <>
                {new Date(params.row.updatedAt).toLocaleString()}
            </>
          );
        }
      }
  ];

const ReportsDisplayTable = ({rows, handleClose}) => {
    console.log(rows)
    return (
        <div>
            <div style={{ height: 300, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    loading={rows ? false : true}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    components={{Toolbar: GridToolbar}}
                />
                <div className="col-md-12 my-3 d-flex justify-content-end">
                    <button onClick={handleClose} className="btn btn-danger">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReportsDisplayTable;