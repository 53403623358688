import * as React from 'react';
import { useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useApp } from '../context/AppProvider';
import { NavLink, useParams } from 'react-router-dom';
import { useLogin } from '../context/LoginProvider';
import { APP_ROLES } from '../helpers/constants';
import CurrencyFormat from 'react-currency-format';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DisplayTable from '../components/DisplayTable';

const InvoiceTable = () => {

  const {filter} = useParams();
  const { user, view } = useLogin()
  
  const [dataToShow, setDataToShow] = useState(filter);
  const {fetch, setFetch, allInvoices} = useApp()
  
  // Filter states
  const filteredData = {
    Payable: {
      pendingPayableInvoices: 'Pending Payable Invoices',
      paidPayableInvoices: 'Paid Payable Invoices',
      allPayableInvoices: 'All Payable Invoices',
      partiallyPaidPayableInvoices: 'Partially Paid Payables'
    },
    Recievable: {
      pendingRecievableInvoices: 'Pending Recievable Invoices',
      paidRecievableInvoices: 'Paid Recievable Invoices',
      allRecievableInvoices: 'All Recievable Invoices',
      partiallyPaidRecievableInvoices: 'Partially Paid Recievables',
    },
    // paidInvoices: 'Paid Invoices',
    allInvoices: 'All Invoices',
    // pendingInvoices: 'Unpaid Invoices',
}
console.log(allInvoices['allInvoices'])
React.useEffect(() => {
  setDataToShow(filter);
}, [filter])
  return (
    <div style={{ height: 500, width: '100%' }}>
      <div className="row mb-3">
        <div className={`col-md-6`}>
          {
              filter !== 'Reports'
            ?
              <h4>{filteredData[view][dataToShow] ? filteredData[view][dataToShow] : 'Payments'}</h4>
            :
              <h4>Reports</h4>
          }
        </div>
        {
            dataToShow === 'Payments'
          ?
            <>
              {
                  view === 'Payable'
                ?
                  <div className="col-md-6">
                    <CurrencyFormat value={allInvoices.partiallyPaidDebtsPayableSum} prefix={'K'} thousandSeparator decimalScale={2} displayType={'text'} renderText={value => <h4>Total Paid: {value}</h4>}/>
                  </div>
                :
                  <div className="col-md-6">
                    <CurrencyFormat value={allInvoices.partiallyPaidDebtsRecievableSum} prefix={'K'} thousandSeparator decimalScale={2} displayType={'text'} renderText={value => <h4>Total Paid: {value}</h4>}/>
                  </div>
              }
            </>
          :
            null
        }
        {
            dataToShow !== 'Payments'
          ?
              <>
                <>
                {
                    view === 'Payable'
                  ?
                    <div className="col-md-6">
                      <CurrencyFormat value={allInvoices.paidDebtsPayableSum} prefix={'K'} thousandSeparator decimalScale={2} displayType={'text'} renderText={value => <h4>Total Paid: {value}</h4>}/>
                    </div>
                  :
                    <div className="col-md-6">
                      <CurrencyFormat value={allInvoices.paidDebtsRecievableSum} prefix={'K'} thousandSeparator decimalScale={2} displayType={'text'} renderText={value => <h4>Total Paid: {value}</h4>}/>
                    </div>
                }
              </>
              <div className="col-md-3 my-auto">
                  <div className="dropdown">
                    <FilterAltIcon  className="dropdown-toggle fs-4" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"/> <span>Filter</span>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      {
                          <>
                            {
                                (user.role === APP_ROLES.generalPayable || user.role === APP_ROLES.admin || user.role === APP_ROLES.manager) && view === 'Payable'
                              ?
                                <>
                                  <span className="dropdown-item" onClick={() => setDataToShow('allPayableInvoices')} role={'button'}>All</span>
                                  <span className="dropdown-item" onClick={() => setDataToShow('paidPayableInvoices')} role={'button'}>Paid</span>
                                  <span className="dropdown-item" onClick={() => setDataToShow('pendingPayableInvoices')} role={'button'}>Unpaid</span>
                                  <span className="dropdown-item" onClick={() => setDataToShow('partiallyPaidPayableInvoices')} role={'button'}>Partially Paid</span>
                                </>
                              :
                                <>
                                  <span className="dropdown-item" onClick={() => setDataToShow('allRecievableInvoices')} role={'button'}>All</span>
                                  <span className="dropdown-item" onClick={() => setDataToShow('paidRecievableInvoices')} role={'button'}>Paid</span>
                                  <span className="dropdown-item" onClick={() => setDataToShow('pendingRecievableInvoices')} role={'button'}>Unpaid</span>
                                  <span className="dropdown-item" onClick={() => setDataToShow('partiallyPaidRecievableInvoices')} role={'button'}>Partially Paid</span>
                                </>
                            }
                          </>
                      }
                    </ul>
                  </div>
              </div>
            </>
          :
            null
        }
      </div>
        <DisplayTable dataToShow={dataToShow} fetch={fetch} setFetch={setFetch} rows={allInvoices} />
          <div className="col-md-12 my-3">
            <NavLink to={'/'} className="btn btn-danger me-3"><ArrowBackIcon/></NavLink>
          </div>
    </div>
  );
}


export default InvoiceTable;