import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import './toastify-style.css';
import { Chip } from '@mui/material';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const notify = ({message=null, error=null, success=null, info=null, position='TOP_CENTER'}) => {
    
    if (success) {
        toast.success(message, {
          position: toast.POSITION[position]
        });
    } else if (error) {
        toast.error(message, {
          position: toast.POSITION[position]
        });
    } else if (info) {
        toast.info(message, {
          position: toast.POSITION[position]
        });
    } else {
        toast.warn(message, {
          position: toast.POSITION[position]
        });
    }
  };


  // Functions to deal with text
  export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  export const formTitle = (str) => {
    str = capitalizeFirstLetter(str);
    const arr = str.match(/[A-Z][a-z]+/g);
    let title = '';
  
    arr.forEach((word) => {
      title = title + `${word} `
    })
  
    return title;
  }

  export const renderPrice = (price) => {
    return <CurrencyFormat decimalScale={2} value={price} displayType={'text'} thousandSeparator={true} prefix={'K'} renderText={value => <span>{value}</span>} />
  }

  export const switchStatus = (status) => {
    if (status === 'Pending') {
        return <Chip className='text-danger' label={status} />
    } else if (status === 'Partially Paid') {
        return <Chip className='text-warning' label={status} />
    } else {
        return <Chip className='text-success' label={status} />
    }
  }

  export const loadAnimation = () => {
    return <Skeleton />
  }