import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import LoginProvider from './context/LoginProvider';
import AppProvider from './context/AppProvider';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));

const formLabelsTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
          asterisk: {
              color: 'red'
          }
      }
    }
  }
});

root.render(
  <LoginProvider>
    <AppProvider>
      <ThemeProvider theme={formLabelsTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </AppProvider>
  </LoginProvider>
);
