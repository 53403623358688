import React from 'react';
import { useLogin } from './context/LoginProvider';
import MainNavigation from './navigations/MainNavigation';
import DefaultNavigation from './navigations/DefaultNavigation';

const App = () => {

  const {isLoggedIn} = useLogin()

  return (
    <>
      {isLoggedIn ? <MainNavigation /> : <DefaultNavigation />}
    </>
  );
};

export default App;